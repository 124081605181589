import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import FartSoundIcon from "@mui/icons-material/Forest";
import MemeIcon from "@mui/icons-material/Surfing";
import GameSoundIcon from "@mui/icons-material/SportsEsports";
import PetsIcon from "@mui/icons-material/Pets";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import Gun from '../icons/Gun';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';
import LoyaltySharpIcon from '@mui/icons-material/LoyaltySharp';
import SentimentSatisfiedAltSharpIcon from '@mui/icons-material/SentimentSatisfiedAltSharp';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import PublicIcon from '@mui/icons-material/Public';
import ForestIcon from '@mui/icons-material/Forest';
import Animation  from '@mui/icons-material/Animation';
import DirectionsCar  from '@mui/icons-material/DirectionsCar';
import LocalMovies  from '@mui/icons-material/LocalMovies';
import MusicNote  from '@mui/icons-material/MusicNote';
import People  from '@mui/icons-material/People';
import Psychology  from '@mui/icons-material/Psychology';
import AddReaction  from '@mui/icons-material/AddReaction';
import AudioFile  from '@mui/icons-material/AudioFile';
import SportsBaseball  from '@mui/icons-material/SportsBaseball';
import LiveTv  from '@mui/icons-material/LiveTv';
import Face2  from '@mui/icons-material/Face';
import Diversity2  from '@mui/icons-material/DeliveryDining';
import WhatsApp  from '@mui/icons-material/WhatsApp';
import HomeIcon  from '@mui/icons-material/Home';
import { Book } from "@mui/icons-material";
import '../index.css'
import GilabLogo from "../icons/favicon.png"
import WidgetsIcon from '@mui/icons-material/Widgets';
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton, DropdownItem } from "react-bootstrap";
import {SplitButton} from "react-bootstrap";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import $ from 'jquery';
import Popper from 'popper.js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const drawerWidth = 240;

const allIcons = [
  <HomeIcon />,
  <TheaterComedyIcon />,
  <MemeIcon />,
  <GameSoundIcon />,
  <PetsIcon />,
  <FartSoundIcon />,
  <EmojiEmotionsIcon />,
  <NotificationsActiveIcon />,
  <RingVolumeIcon />,
  <FlutterDashIcon />,
  <LoyaltySharpIcon />,
  <SentimentSatisfiedAltSharpIcon />,
  <ThunderstormIcon />,
  <MoodBadIcon />,
  <PublicIcon />,
  <ForestIcon />,
  <Gun />,
  <Animation/> ,
  <MoodBadIcon/> ,
  <DirectionsCar/>,
  <LocalMovies/>,
  <MusicNote/>,
  <People/>,
  <Psychology/>,
  <AddReaction/>,
  <AudioFile/>,
  <SportsBaseball/>,
  <LiveTv/>,
  <Face2/>,
  <Diversity2/>,
  <WhatsApp/>
];
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function RedirectPage(path, isExternal) {
    (isExternal && false) ? window.open(path, "_blank") : window.location.href = path;
}

function TopSoundsSubMenuComp(props) {
  const direction = props.isPhoneMenu && false ? "start": "down";
  const subMenu = TopSoundsSubMenu();

  const PlaceSubMenuItems = () => {
    const result = [];
    subMenu.map((soundInfo) => {
      result.push(<Dropdown.Item className = "TopSoundItem" href={soundInfo.Path} key={soundInfo.Path}>{soundInfo.Name}</Dropdown.Item>);
    });
    return result;
  }
  
  return(
    <div className="SubDropDown">
            <DropdownButton 
  title = {
  <div
  style={{display: "inline-flex", marginInline: "0.5rem", padding: "0"}}
  >
    <LibraryMusicIcon style={{marginInline:"0.2rem"}}></LibraryMusicIcon> {props.title}
  </div>} 
  drop={direction}
  id="SubMenu"
>
      {PlaceSubMenuItems()}
      </DropdownButton>
    </div>
  );
}

function DropDownMenu() {
  const [show, setShow] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}
  const arr = GetMenuItems();
  return(
    <DropdownButton 
      id="dropdown-basic-button"
      title={<WidgetsIcon style={{paddingInline:"0"}}></WidgetsIcon>}
      onMouseEnter={showDropdown} 
      onMouseLeave={hideDropdown}
    >
      {arr.map((item) => (
        !item.SubMenu ? 
          <div>
            <Dropdown.Item onClick={()=> RedirectPage(item.Path, item.IsExternal)}>{item.Icon} {item.Name}
            </Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
          </div> :
          <div>

              <TopSoundsSubMenuComp
                isPhoneMenu = {true}
                title = {item.Name}
              >
              </TopSoundsSubMenuComp>
          </div>
        ))}

    </DropdownButton>
  );
}

function GetMenuItems() {
  return [
    {
      Name: 'Home',
      Icon: <HomeIcon/>,
      Path: '/',
      IsExternal: false,
      SubMenu: false
    },
    {
      Name: 'Memes',
      Icon: <EmojiEmotionsIcon/>,
      Path: '/memes/',
      IsExternal: true,
      SubMenu: false
    },
    {
      Name: 'Blog',
      Icon: <Book/>,
      Path: '/blog/',
      IsExternal: true,
      SubMenu: false
    },
    {
      Name: 'Top Sounds',
      Icon: <Book/>,
      Path: '/search/fart/',
      IsExternal: false,
      SubMenu: true
    }
  ];
}

function TopSoundsSubMenu(){
  return [
    {
      Name: 'Fart Sounds',
      Path: '/search/fart/'
    },
    {
      Name: 'Moan Sounds',
      Path: '/search/moan/'
    },
    {
      Name: 'Fornite Sounds',
      Path: '/search/fortnite/'
    },
    {
      Name: 'Vine Boom Sounds',
      Path: '/search/vine%20boom/'
    },
    {
      Name: 'iPhone Sounds',
      Path: '/search/iphone/'
    },
    {
      Name: 'Indian Sounds',
      Path: '/search/indian/'
    },
  ]
}

function BarMenu() {
  const menuItems = GetMenuItems();

  return(
    menuItems.map((item) => (
    !item.SubMenu ? 
    <div
    style={{display: "inline-flex", marginInline: "0.5rem", }} 
    className="MenuItem"
    onClick={() => {RedirectPage(item.Path, item.IsExternal)}}
    >
      {typeof item.Icon === 'string' ? React.createElement(item.Icon) : item.Icon}
        <Typography style={{marginInline: "0.2rem"}}>{item.Name}</Typography>
    </div> :
    <div
        style={{display: "inline-flex", marginInline: "0.5rem", }} 
        className="MenuItem"
        >
        <TopSoundsSubMenuComp
            isPhoneMenu = {false}
            title = {item.Name}
          >
          </TopSoundsSubMenuComp>
    </div>
))
);
}

const allPages = [
  "",
  "All_SoundBoard",
  "Meme_SoundBoard",
  "games_sounds",
  "animals",
  "Sleep_Soundboard",
  "Funny_SoundBoard"
  , "Notifications_SoundBoard"
  , "RingTunes_Soundboard"
  , "Bird_SoundBoard"
  , "whoosh_soundboard"
  , "Laugh_Soundboard"
  , "Rain_SoundBoard"
  , "Horror_SoundBoard"
  , "Nature_Sounds"
  , "Nature_SoundBoard"
  , "Gun_SoundBoard",
  "Anime_Sounds",
  "Annoying_SoundBoard",
  "Car_SoundBoard",
  "Movie_SoundBoard",
  "Music_SoundBoard",
  "Politcs_SoundBoard",
  "Prank_SoundBoard",
  "Reaction_SoundBoard",
  "SoundEffect_SoundBoard",
  "Sport_SoundBoard",
  "Television_SoundBoard",
  "Tiktok_SoundBoard",
  "Viral_SoundBoard",
  "Whatsapp_SoundBoard"
];

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const navigate = useNavigate();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [searchTextt, changeSearchText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(props.selected);
  const { soundName } = useParams();
  const { searchText } = useParams();
  const { name } = useParams();


  // setSelectedIndex(allPages.indexOf(name));


  console.log(allPages.indexOf(name));
  
  const handleDrawerState = () => {
    if(open === false)
    {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={handleDrawerState}
            aria-label="open drawer"
            sx={{ mr: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <img 
          style={{"max-width":"100%", "max-height":"2rem", marginLeft: "auto", marginRight: "0.5rem"}} 
          src ={GilabLogo} 
          alt="MyInstants Logo"
          onClick={() => {window.location.href="/"}}
          className="CustomButton"
          >
          </img>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{marginLeft: "0.5rem"}}
            className="HideOnMobile CustomButton"
            // sx={{ flexGrow: 1, display: { xs: "none", sm: "block" }}}
          >
            {soundName === undefined
              ? searchText === undefined
                ? <div>
                  <a href="/" style={{color: "inherit", textDecoration: "inherit"}}>Myinstants - Meme Soundboard & Sound Buttons</a>
                </div>
                : "Search Results For " + searchText
              : soundName}
          </Typography>

          <Box 
          sx={{ alignItems: 'center', textAlign: 'center' }} 
          style={{ marginLeft: "10%", marginRight: "1%"}}
          className="HideOnMobile"
          >
            <BarMenu></BarMenu>
          </Box>
        <Search style={{marginRight: "0", marginLeft: "0"}}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            style={{ color: "#1a1a1a" }}
            placeholder="Search Sound..."
            inputProps={{ "aria-label": "search" }}
            value={searchTextt}
            onChange={(event) => {
              changeSearchText(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && searchTextt.length > 0)
                navigate("/search/" + encodeURIComponent(searchTextt));
            }}
          />
        </Search>
        <div className="ShowOnMobile" style={{marginInline: "0.5rem"}}>
          <DropDownMenu></DropDownMenu>
        </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} style={{whiteSpace: open ? "normal" : "nowrap"}}>
        <DrawerHeader>
        {/* <DrawerHeader style={{justifyContent: "space-between"}}> */}
          {/* <img src={require("../icons/favicon.png")} style={{maxHeight: "-webkit-fill-available"}}></img> */}
          <IconButton aria-label="drawer" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            "Home",
            "All Meme Soundboard",
            "Meme Sounds",
            "Game Sounds",
            "Animals Sounds",
            "Sleep Music & Sounds",
            "Funny Soundboard",
            "Notifications",
            "RingTones",
            "Birds Soundboard",
            "Whoosh Soundboard",
            "Laugh Soundboard"
            , "Rain Soundboard"
            , "Horror Soundboard"
            , "Earth Sounds"
            , "Nature Soundboard",
            "Gun Soundboard",
            "Anime Sounds",
            "Annoying Soundboard",
            "Car Soundboard",
            "Movie Soundboard",
            "Music Soundboard",
            "Politics Soundboard",
            "Prank Soundboard",
            "Reaction Soundboard",
            "SoundEffect Soundboard",
            "Sport Soundboard",
            "Television Soundboard",
            "Tiktok Soundboard",
            "Viral Soundboard",
            "Whatsapp Soundboard"

          ].map((text, index) => (
            <li key={text}>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(index);
                  navigate("/" + allPages[index]);
                  navigate(0);
                  props.setActiveSounds([0, 20]);
                  // props.changeSearchText('')
                }}
                key={text}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={


                  name === undefined ? index === selectedIndex
                    ? { background: "rgba(0,0,0,0.17)" }
                    : {} : index === allPages.indexOf(name) ? { background: "rgba(0,0,0,0.17)" } : {}

                }
              >
                <ListItemIcon
                  key={index}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {allIcons[index]}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </li>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}
