
function PrivacyPolicy() {

    return (


         <div  style={{textAlign:"left"}}>
            <div>
                <strong>
                    <span style={{ fontSize: 26 }}>
                        <span data-custom-class="title">
                            <bdt className="block-component" />
                            <bdt className="question">PRIVACY POLICY</bdt>
                            <bdt className="statement-end-if-in-editor" />
                        </span>
                    </span>
                </strong>
            </div>
            <div>
                <br />
            </div>
            <div>
                <span style={{ color: " rgb(0, 0, 0)" }}>
                    <strong>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="subtitle">
                                Last updated <bdt className="question">May 01, 2023</bdt>
                            </span>
                        </span>
                    </strong>
                </span>
            </div>
            <div>
                <br />
            </div>
            <div>
                <br />
            </div>
            <div>
                <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: " rgb(0, 0, 0)" }}>
                    <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            This privacy notice for{" "}
                            <bdt className="question">
                                My Instants
                                <bdt className="block-component" />
                            </bdt>{" "}
                            (<bdt className="block-component" />"
                            <bdt className="block-component" />
                            <strong>Company</strong>
                            <bdt className="statement-end-if-in-editor" />
                            ," "<strong>we</strong>," "<strong>us</strong>," or "
                            <strong>our</strong>"<bdt className="statement-end-if-in-editor" />
                        </span>
                        <span data-custom-class="body_text">
                            ), describes how and why we might collect, store, use, and/or share (
                            <bdt className="block-component" />"<strong>process</strong>"
                            <bdt className="statement-end-if-in-editor" />) your information when
                            you use our services (<bdt className="block-component" />"
                            <strong>Services</strong>"
                            <bdt className="statement-end-if-in-editor" />
                            ), such as when you:
                        </span>
                    </span>
                </span>
                <span style={{ fontSize: 15 }}>
                    <span style={{ color: " rgb(0, 0, 0)" }}>
                        <span data-custom-class="body_text">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <ul>
                <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                Visit our website
                                <bdt className="block-component" /> at{" "}
                                <bdt className="question">
                                    <a
                                        href="https://myinstants.org/"
                                        target="_blank"
                                        data-custom-class="link"
                                    >
                                        https://myinstants.org/
                                    </a>
                                </bdt>
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                                <span style={{ color: " rgb(0, 0, 0))" }}>
                                                    <bdt className="statement-end-if-in-editor">
                                                        , or any website of ours that links to this privacy
                                                        notice
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </li>
            </ul>
            <div>
                <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                        <span style={{ fontSize: 15 }}>
                            <span style={{ color: " rgb(0, 0, 0)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </bdt>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span data-custom-class="body_text">
                                <span style={{ color: " rgb(0, 0, 0))" }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <ul>
                    <li style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                <span data-custom-class="body_text">
                                    Engage with us in other related ways, including any sales,
                                    marketing, or events
                                    <span style={{ fontSize: 15 }}>
                                        <span style={{ color: " rgb(0, 0, 0))" }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                                        <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span data-custom-class="body_text">
                                <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
                                notice will help you understand your privacy rights and choices. If
                                you do not agree with our policies and practices, please do not use
                                our Services. If you still have any questions or concerns, please
                                contact us at <bdt className="question">mail@myinstants.org</bdt>.
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <strong>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                        </span>
                    </strong>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>
                                <em>
                                    This summary provides key points from our privacy notice, but you
                                    can find out more details about any of these topics by clicking
                                    the link following each key point or by using our&nbsp;
                                </em>
                            </strong>
                        </span>
                    </span>
                    <a data-custom-class="link" href="#toc">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>
                                    <em>table of contents</em>
                                </strong>
                            </span>
                        </span>
                    </a>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>
                                <em>&nbsp;below to find the section you are looking for.</em>
                            </strong>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>What personal information do we process?</strong> When you
                            visit, use, or navigate our Services, we may process personal
                            information depending on how you interact with{" "}
                            <bdt className="block-component" />
                            <bdt className="question">My Instants</bdt>
                            <bdt className="statement-end-if-in-editor" /> and the Services, the
                            choices you make, and the products and features you use. Learn more
                            about&nbsp;
                        </span>
                    </span>
                    <a data-custom-class="link" href="#personalinfo">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                personal information you disclose to us
                            </span>
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>Do we process any sensitive personal information?</strong>{" "}
                            <bdt className="block-component" />
                            We do not process sensitive personal information.
                            <bdt className="else-block" />
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>Do we receive any information from third parties?</strong>{" "}
                            <bdt className="block-component" />
                            We may receive information from public databases, marketing partners,
                            social media platforms, and other outside sources. Learn more
                            about&nbsp;
                        </span>
                    </span>
                    <a data-custom-class="link" href="#othersources">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                information collected from other sources
                            </span>
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>How do we process your information?</strong> We process your
                            information to provide, improve, and administer our Services,
                            communicate with you, for security and fraud prevention, and to comply
                            with law. We may also process your information for other purposes with
                            your consent. We process your information only when we have a valid
                            legal reason to do so. Learn more about&nbsp;
                        </span>
                    </span>
                    <a data-custom-class="link" href="#infouse">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                how we process your information
                            </span>
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>
                                In what situations and with which{" "}
                                <bdt className="block-component" />
                                parties do we share personal information?
                            </strong>{" "}
                            We may share information in specific situations and with specific{" "}
                            <bdt className="block-component" />
                            third parties. Learn more about&nbsp;
                        </span>
                    </span>
                    <a data-custom-class="link" href="#whoshare">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                when and with whom we share your personal information
                            </span>
                        </span>
                    </a>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            .<bdt className="block-component" />
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>What are your rights?</strong> Depending on where you are
                            located geographically, the applicable privacy law may mean you have
                            certain rights regarding your personal information. Learn more
                            about&nbsp;
                        </span>
                    </span>
                    <a data-custom-class="link" href="#privacyrights">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">your privacy rights</span>
                        </span>
                    </a>
                    <span data-custom-class="body_text">.</span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>How do you exercise your rights?</strong> The easiest way to
                            exercise your rights is by{" "}
                            <bdt className="block-component">submitting a&nbsp;</bdt>
                        </span>
                    </span>
                    <a
                        data-custom-class="link"
                        href="https://app.termly.io/notify/3be090b0-aa16-43f2-aa38-8b31785b0b57"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">data subject access request</span>
                        </span>
                    </a>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="block-component" />, or by contacting us. We will
                            consider and act upon any request in accordance with applicable data
                            protection laws.
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            Want to learn more about what <bdt className="block-component" />
                            <bdt className="question">My Instants</bdt>
                            <bdt className="statement-end-if-in-editor" /> does with any
                            information we collect?&nbsp;
                        </span>
                    </span>
                    <a data-custom-class="link" href="#toc">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                Review the privacy notice in full
                            </span>
                        </span>
                    </a>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">.</span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div id="toc" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                                </strong>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#infocollect">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                        </a>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#infouse">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                2. HOW DO WE PROCESS YOUR INFORMATION?
                                <bdt className="block-component" />
                            </span>
                        </a>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#legalbases">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                3.{" "}
                                <span style={{ fontSize: 15 }}>
                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                        WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                                        INFORMATION?
                                    </span>
                                </span>
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                        </a>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: " rgb(0, 0, 0))" }}>
                            <a data-custom-class="link" href="#whoshare">
                                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                            </a>
                        </span>
                        <span data-custom-class="body_text">
                            <bdt className="block-component" />
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a href="#3pwebsites">
                            <span data-custom-class="link">
                                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                        </a>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                                <span data-custom-class="body_text">
                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                        <span style={{ color: " rgb(0, 0, 0))" }}>
                                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#intltransfers">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                            </span>
                        </a>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                        <bdt className="statement-end-if-in-editor" />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#inforetain">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                7. HOW LONG DO WE KEEP YOUR INFORMATION?
                            </span>
                        </a>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                        <span style={{ color: " rgb(0, 0, 0))" }}>
                                            <bdt className="block-component" />
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: " rgb(0, 0, 0))" }}>
                            <a data-custom-class="link" href="#privacyrights">
                                8. WHAT ARE YOUR PRIVACY RIGHTS?
                            </a>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#DNT">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                9. CONTROLS FOR DO-NOT-TRACK FEATURES
                            </span>
                        </a>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#caresidents">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                            </span>
                        </a>
                    </span>
                    <bdt className="block-component">
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text" />
                        </span>
                    </bdt>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <a data-custom-class="link" href="#policyupdates">
                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                11. DO WE MAKE UPDATES TO THIS NOTICE?
                            </span>
                        </a>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <a data-custom-class="link" href="#contact">
                        <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </span>
                    </a>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <a data-custom-class="link" href="#request">
                        <span style={{ color: " rgb(0, 0, 0))" }}>
                            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                            YOU?
                        </span>
                    </a>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div id="infocollect" style={{ lineHeight: "1.5" }}>
                    <span style={{ color: " rgb(0, 0, 0)" }}>
                        <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                            <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                        <strong>
                                            <span data-custom-class="heading_1">
                                                1. WHAT INFORMATION DO WE COLLECT?
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div id="personalinfo" style={{ lineHeight: "1.5" }}>
                    <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                        <span style={{ fontSize: 15 }}>
                            <strong>Personal information you disclose to us</strong>
                        </span>
                    </span>
                </div>
                <div>
                    <div>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                            <span data-custom-class="body_text">
                                                <strong>
                                                    <em>In Short:</em>
                                                </strong>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                            <span data-custom-class="body_text">
                                                <strong>
                                                    <em>&nbsp;</em>
                                                </strong>
                                                <em>
                                                    We collect personal information that you provide to us.
                                                </em>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                We collect personal information that you voluntarily provide to us
                                when you{" "}
                                <span style={{ fontSize: 15 }}>
                                    <bdt className="block-component" />
                                </span>
                            </span>
                            <span data-custom-class="body_text">
                                express an interest in obtaining information about us or our
                                products and Services, when you participate in activities on the
                                Services, or otherwise when you contact us.
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                    <bdt className="block-component" />
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <strong>Sensitive Information.</strong>{" "}
                            <bdt className="block-component" />
                            We do not process sensitive information.
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="else-block" />
                        </span>
                    </span>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component">
                                            <bdt className="block-component" />
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <bdt className="block-component" />
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                All personal information that you provide to us must be true,
                                complete, and accurate, and you must notify us of any changes to
                                such personal information.
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                <bdt className="block-component" />
                            </span>
                            <span data-custom-class="body_text">
                                <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="statement-end-if-in-editor">
                                                    <bdt className="block-component" />
                                                </bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div id="othersources" style={{ lineHeight: "1.5" }}>
                    <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                        <span style={{ fontSize: 15 }}>
                            <strong>Information collected from other sources</strong>
                        </span>
                    </span>
                </div>
                <div>
                    <div>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                            <span data-custom-class="body_text">
                                                <strong>
                                                    <em>In Short:&nbsp;</em>
                                                </strong>
                                                <em>
                                                    We may collect limited data from public databases,
                                                    marketing partners,{" "}
                                                    <span style={{ color: " rgb(0, 0, 0))" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                            <em>
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                </span>
                                                            </em>
                                                        </span>
                                                    </span>
                                                    and other outside sources.
                                                </em>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                In order to enhance our ability to provide relevant marketing,
                                offers, and services to you and update our records, we may obtain
                                information about you from other sources, such as public databases,
                                joint marketing partners, affiliate programs, data providers,
                                <span style={{ color: " rgb(0, 0, 0))" }}>
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                    &nbsp;
                                </span>
                                and from other third parties. This information includes mailing
                                addresses, job titles, email addresses, phone numbers, intent data
                                (or user <bdt className="block-component" />
                                behavior
                                <bdt className="statement-end-if-in-editor" /> data), Internet
                                Protocol (IP) addresses, social media profiles, social media URLs,
                                and custom profiles, for purposes of targeted advertising and event
                                promotion.
                                <span style={{ color: " rgb(0, 0, 0))" }}>
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                <span style={{ color: " rgb(0, 0, 0))" }}>
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: " rgb(0, 0, 0))" }}>
                                                <span style={{ color: " rgb(0, 0, 0))" }}>
                                                    <bdt className="block-component">
                                                        <span
                                                            style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}
                                                        >
                                                            <span data-custom-class="body_text">
                                                                <span
                                                                    style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor">
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </bdt>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            <bdt className="block-component" />
                        </span>
                    </span>
                </div>
                <div id="infouse" style={{ lineHeight: "1.5" }}>
                    <span style={{ color: " rgb(0, 0, 0)" }}>
                        <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                            <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                        <strong>
                                            <span data-custom-class="heading_1">
                                                2. HOW DO WE PROCESS YOUR INFORMATION?
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ color: " rgb(0, 0, 0)" }}>
                            <span style={{ color: " rgb(0, 0, 0))", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                            <span data-custom-class="body_text">
                                                <strong>
                                                    <em>In Short:&nbsp;</em>
                                                </strong>
                                                <em>
                                                    We process your information to provide, improve, and
                                                    administer our Services, communicate with you, for
                                                    security and fraud prevention, and to comply with law. We
                                                    may also process your information for other purposes with
                                                    your consent.
                                                </em>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span data-custom-class="body_text">
                                <strong>
                                    We process your personal information for a variety of reasons,
                                    depending on how you interact with our Services, including:
                                </strong>
                                <bdt className="block-component" />
                            </span>
                        </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                            <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                </span>
                            </span>
                        </span>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                        <span style={{ fontSize: 15, color: " rgb(0, 0, 0))" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                    <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                            <span style={{ fontSize: 15 }} />
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                                <span style={{ fontSize: 15 }} />
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }} />
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }}>
                                                            <span data-custom-class="body_text" />
                                                        </span>
                                                    </bdt>
                                                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                            <span style={{ fontSize: 15 }} />
                                                        </bdt>
                                                    </p>
                                                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                            <span style={{ fontSize: 15 }} />
                                                        </bdt>
                                                    </p>
                                                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                        <bdt className="block-component" />
                                                    </p>
                                                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                        <bdt className="block-component" />
                                                    </p>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                            <span style={{ fontSize: 15 }} />
                                                        </bdt>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                            <bdt className="block-component">
                                                                <span style={{ fontSize: 15 }} />
                                                            </bdt>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                                <bdt className="block-component">
                                                                    <span style={{ fontSize: 15 }} />
                                                                </bdt>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <bdt className="block-component">
                                                                            <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                    </span>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <bdt className="block-component">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text" />
                                                                            </span>
                                                                        </bdt>
                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                            <bdt className="block-component">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text" />
                                                                                </span>
                                                                            </bdt>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <bdt className="block-component">
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span data-custom-class="body_text" />
                                                                                    </span>
                                                                                </bdt>
                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                    <bdt className="block-component">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text" />
                                                                                        </span>
                                                                                    </bdt>
                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                        <bdt className="block-component">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text" />
                                                                                            </span>
                                                                                        </bdt>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <bdt className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </bdt>
                                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                                <bdt className="block-component">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text" />
                                                                                                    </span>
                                                                                                </bdt>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <bdt className="block-component">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                    <div
                                                                                                        style={{ lineHeight: "1.5" }}
                                                                                                    >
                                                                                                        <bdt className="block-component">
                                                                                                            <span
                                                                                                                style={{ fontSize: 15 }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text" />
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                        <div
                                                                                                            style={{ lineHeight: "1.5" }}
                                                                                                        >
                                                                                                            <bdt className="block-component">
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                </span>
                                                                                                            </bdt>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                            </div>
                                                                                                            <ul>
                                                                                                                <li
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <strong>
                                                                                                                                To save or protect
                                                                                                                                an individual's
                                                                                                                                vital interest.
                                                                                                                            </strong>{" "}
                                                                                                                            We may process your
                                                                                                                            information when
                                                                                                                            necessary to save or
                                                                                                                            protect an
                                                                                                                            individual’s vital
                                                                                                                            interest, such as to
                                                                                                                            prevent harm.
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <br />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                id="legalbases"
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <strong>
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="heading_1">
                                                                                                                            3. WHAT LEGAL BASES DO
                                                                                                                            WE RELY ON TO PROCESS
                                                                                                                            YOUR INFORMATION?
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </strong>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <br />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <em>
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <strong>
                                                                                                                                In Short:&nbsp;
                                                                                                                            </strong>
                                                                                                                            We only process your
                                                                                                                            personal information
                                                                                                                            when we believe it is
                                                                                                                            necessary and we have
                                                                                                                            a valid legal reason
                                                                                                                            (i.e.
                                                                                                                            <bdt className="block-component" />
                                                                                                                            ,
                                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                            legal basis) to do so
                                                                                                                            under applicable law,
                                                                                                                            like with your
                                                                                                                            consent, to comply
                                                                                                                            with laws, to provide
                                                                                                                            you with services to
                                                                                                                            enter into or{" "}
                                                                                                                            <bdt className="block-component" />
                                                                                                                            fulfill
                                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                            our contractual
                                                                                                                            obligations, to
                                                                                                                            protect your rights,
                                                                                                                            or to{" "}
                                                                                                                            <bdt className="block-component" />
                                                                                                                            fulfill
                                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                            our legitimate
                                                                                                                            business interests.
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </em>
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <br />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <em>
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <strong>
                                                                                                                                <u>
                                                                                                                                    If you are located
                                                                                                                                    in the EU or UK,
                                                                                                                                    this section
                                                                                                                                    applies to you.
                                                                                                                                </u>
                                                                                                                            </strong>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </em>
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <br />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        The General Data
                                                                                                                        Protection Regulation
                                                                                                                        (GDPR) and UK GDPR
                                                                                                                        require us to explain
                                                                                                                        the valid legal bases we
                                                                                                                        rely on in order to
                                                                                                                        process your personal
                                                                                                                        information. As such, we
                                                                                                                        may rely on the
                                                                                                                        following legal bases to
                                                                                                                        process your personal
                                                                                                                        information:
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <ul>
                                                                                                                <li
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <strong>
                                                                                                                                Consent.&nbsp;
                                                                                                                            </strong>
                                                                                                                            We may process your
                                                                                                                            information if you
                                                                                                                            have given us
                                                                                                                            permission (i.e.
                                                                                                                            <bdt className="block-component" />
                                                                                                                            ,
                                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                            consent) to use your
                                                                                                                            personal information
                                                                                                                            for a specific
                                                                                                                            purpose. You can
                                                                                                                            withdraw your consent
                                                                                                                            at any time. Learn
                                                                                                                            more about&nbsp;
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <a
                                                                                                                        data-custom-class="link"
                                                                                                                        href="#withdrawconsent"
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                withdrawing your
                                                                                                                                consent
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        .
                                                                                                                    </span>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "1.5"
                                                                                                                }}
                                                                                                            >
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="block-component">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                    <bdt className="block-component">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                </div>
                                                                                                                <ul>
                                                                                                                    <li
                                                                                                                        style={{
                                                                                                                            lineHeight: "1.5"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <strong>
                                                                                                                                    Legal Obligations.
                                                                                                                                </strong>{" "}
                                                                                                                                We may process your
                                                                                                                                information where we
                                                                                                                                believe it is
                                                                                                                                necessary for
                                                                                                                                compliance with our
                                                                                                                                legal obligations,
                                                                                                                                such as to cooperate
                                                                                                                                with a law
                                                                                                                                enforcement body or
                                                                                                                                regulatory agency,
                                                                                                                                exercise or defend
                                                                                                                                our legal rights, or
                                                                                                                                disclose your
                                                                                                                                information as
                                                                                                                                evidence in
                                                                                                                                litigation in which
                                                                                                                                we are involved.
                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                <br />
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="block-component">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                </div>
                                                                                                                <ul>
                                                                                                                    <li
                                                                                                                        style={{
                                                                                                                            lineHeight: "1.5"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <strong>
                                                                                                                                    Vital Interests.
                                                                                                                                </strong>{" "}
                                                                                                                                We may process your
                                                                                                                                information where we
                                                                                                                                believe it is
                                                                                                                                necessary to protect
                                                                                                                                your vital interests
                                                                                                                                or the vital
                                                                                                                                interests of a third
                                                                                                                                party, such as
                                                                                                                                situations involving
                                                                                                                                potential threats to
                                                                                                                                the safety of any
                                                                                                                                person.
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                            </span>
                                                                                                                        </bdt>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="block-component">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                    <bdt className="block-component">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <br />
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    id="whoshare"
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                " rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color:
                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color:
                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                        color:
                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        id="control"
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <strong>
                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                4. WHEN AND
                                                                                                                                                WITH WHOM DO
                                                                                                                                                WE SHARE
                                                                                                                                                YOUR
                                                                                                                                                PERSONAL
                                                                                                                                                INFORMATION?
                                                                                                                                            </span>
                                                                                                                                        </strong>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <br />
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color:
                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <strong>
                                                                                                                                    <em>In Short:</em>
                                                                                                                                </strong>
                                                                                                                                <em>
                                                                                                                                    &nbsp;We may share
                                                                                                                                    information in
                                                                                                                                    specific
                                                                                                                                    situations
                                                                                                                                    described in this
                                                                                                                                    section and/or
                                                                                                                                    with the following{" "}
                                                                                                                                    <bdt className="block-component" />
                                                                                                                                    third parties.
                                                                                                                                </em>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color:
                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <br />
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            We{" "}
                                                                                                                            <bdt className="block-component" />
                                                                                                                            may need to share your
                                                                                                                            personal information
                                                                                                                            in the following
                                                                                                                            situations:
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <ul>
                                                                                                                    <li
                                                                                                                        style={{
                                                                                                                            lineHeight: "1.5"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <strong>
                                                                                                                                    Business
                                                                                                                                    Transfers.
                                                                                                                                </strong>{" "}
                                                                                                                                We may share or
                                                                                                                                transfer your
                                                                                                                                information in
                                                                                                                                connection with, or
                                                                                                                                during negotiations
                                                                                                                                of, any merger, sale
                                                                                                                                of company assets,
                                                                                                                                financing, or
                                                                                                                                acquisition of all
                                                                                                                                or a portion of our
                                                                                                                                business to another
                                                                                                                                company.
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <bdt className="block-component" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            lineHeight: "1.5"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                            </bdt>
                                                                                                                        </span>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                </span>
                                                                                                                            </bdt>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt className="block-component">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                    </span>
                                                                                                                                </bdt>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        lineHeight:
                                                                                                                                            "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <bdt className="block-component">
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                        </span>
                                                                                                                                    </bdt>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <strong>
                                                                                                                                            <span
                                                                                                                                                id="3pwebsites"
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                    5. WHAT IS
                                                                                                                                                    OUR STANCE
                                                                                                                                                    ON
                                                                                                                                                    THIRD-PARTY
                                                                                                                                                    WEBSITES?
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </strong>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <strong>
                                                                                                                                                    <em>
                                                                                                                                                        In
                                                                                                                                                        Short:
                                                                                                                                                    </em>
                                                                                                                                                </strong>
                                                                                                                                                <em>
                                                                                                                                                    &nbsp;We
                                                                                                                                                    are not
                                                                                                                                                    responsible
                                                                                                                                                    for the
                                                                                                                                                    safety of
                                                                                                                                                    any
                                                                                                                                                    information
                                                                                                                                                    that you
                                                                                                                                                    share with
                                                                                                                                                    third
                                                                                                                                                    parties
                                                                                                                                                    that we
                                                                                                                                                    may link
                                                                                                                                                    to or who
                                                                                                                                                    advertise
                                                                                                                                                    on our
                                                                                                                                                    Services,
                                                                                                                                                    but are
                                                                                                                                                    not
                                                                                                                                                    affiliated
                                                                                                                                                    with, our
                                                                                                                                                    Services.
                                                                                                                                                </em>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                The Services
                                                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                                                may link to
                                                                                                                                                third-party
                                                                                                                                                websites,
                                                                                                                                                online
                                                                                                                                                services, or
                                                                                                                                                mobile
                                                                                                                                                applications
                                                                                                                                                and/or
                                                                                                                                                contain
                                                                                                                                                advertisements
                                                                                                                                                from third
                                                                                                                                                parties that
                                                                                                                                                are not
                                                                                                                                                affiliated
                                                                                                                                                with us and
                                                                                                                                                which may
                                                                                                                                                link to
                                                                                                                                                other
                                                                                                                                                websites,
                                                                                                                                                services, or
                                                                                                                                                applications.
                                                                                                                                                Accordingly,
                                                                                                                                                we do not
                                                                                                                                                make any
                                                                                                                                                guarantee
                                                                                                                                                regarding
                                                                                                                                                any such
                                                                                                                                                third
                                                                                                                                                parties, and
                                                                                                                                                we will not
                                                                                                                                                be liable
                                                                                                                                                for any loss
                                                                                                                                                or damage
                                                                                                                                                caused by
                                                                                                                                                the use of
                                                                                                                                                such
                                                                                                                                                third-party
                                                                                                                                                websites,
                                                                                                                                                services, or
                                                                                                                                                applications.
                                                                                                                                                The
                                                                                                                                                inclusion of
                                                                                                                                                a link
                                                                                                                                                towards a
                                                                                                                                                third-party
                                                                                                                                                website,
                                                                                                                                                service, or
                                                                                                                                                application
                                                                                                                                                does not
                                                                                                                                                imply an
                                                                                                                                                endorsement
                                                                                                                                                by us. We
                                                                                                                                                cannot
                                                                                                                                                guarantee
                                                                                                                                                the safety
                                                                                                                                                and privacy
                                                                                                                                                of data you
                                                                                                                                                provide to
                                                                                                                                                any third
                                                                                                                                                parties. Any
                                                                                                                                                data
                                                                                                                                                collected by
                                                                                                                                                third
                                                                                                                                                parties is
                                                                                                                                                not covered
                                                                                                                                                by this
                                                                                                                                                privacy
                                                                                                                                                notice. We
                                                                                                                                                are not
                                                                                                                                                responsible
                                                                                                                                                for the
                                                                                                                                                content or
                                                                                                                                                privacy and
                                                                                                                                                security
                                                                                                                                                practices
                                                                                                                                                and policies
                                                                                                                                                of any third
                                                                                                                                                parties,
                                                                                                                                                including
                                                                                                                                                other
                                                                                                                                                websites,
                                                                                                                                                services, or
                                                                                                                                                applications
                                                                                                                                                that may be
                                                                                                                                                linked to or
                                                                                                                                                from the
                                                                                                                                                Services.
                                                                                                                                                You should
                                                                                                                                                review the
                                                                                                                                                policies of
                                                                                                                                                such third
                                                                                                                                                parties and
                                                                                                                                                contact them
                                                                                                                                                directly to
                                                                                                                                                respond to
                                                                                                                                                your
                                                                                                                                                questions.
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                <span data-custom-class="heading_1" />
                                                                                                                                                            </bdt>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            color:
                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                        </span>
                                                                                                                                                                        <bdt className="block-component">
                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                            </span>
                                                                                                                                                                        </bdt>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="intltransfers"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    6.
                                                                                                                                                                    IS
                                                                                                                                                                    YOUR
                                                                                                                                                                    INFORMATION
                                                                                                                                                                    TRANSFERRED
                                                                                                                                                                    INTERNATIONALLY?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:&nbsp;
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        We may
                                                                                                                                                        transfer,
                                                                                                                                                        store,
                                                                                                                                                        and
                                                                                                                                                        process
                                                                                                                                                        your
                                                                                                                                                        information
                                                                                                                                                        in
                                                                                                                                                        countries
                                                                                                                                                        other
                                                                                                                                                        than
                                                                                                                                                        your
                                                                                                                                                        own.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        data-custom-class="body_text"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    Our
                                                                                                                                                    servers
                                                                                                                                                    are
                                                                                                                                                    located in
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="forloop-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                . If
                                                                                                                                                                you
                                                                                                                                                                are
                                                                                                                                                                accessing
                                                                                                                                                                our
                                                                                                                                                                Services
                                                                                                                                                                from
                                                                                                                                                                outside
                                                                                                                                                            </span>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            color:
                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                            <bdt className="forloop-component" />
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                ,
                                                                                                                                                                please
                                                                                                                                                                be
                                                                                                                                                                aware
                                                                                                                                                                that
                                                                                                                                                                your
                                                                                                                                                                information
                                                                                                                                                                may
                                                                                                                                                                be
                                                                                                                                                                transferred
                                                                                                                                                                to,
                                                                                                                                                                stored,
                                                                                                                                                                and
                                                                                                                                                                processed
                                                                                                                                                                by
                                                                                                                                                                us
                                                                                                                                                                in
                                                                                                                                                                our
                                                                                                                                                                facilities
                                                                                                                                                                and
                                                                                                                                                                by
                                                                                                                                                                those
                                                                                                                                                                third
                                                                                                                                                                parties
                                                                                                                                                                with
                                                                                                                                                                whom
                                                                                                                                                                we
                                                                                                                                                                may
                                                                                                                                                                share
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                (see{" "}
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                "
                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="#whoshare"
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                WHEN
                                                                                                                                                                AND
                                                                                                                                                                WITH
                                                                                                                                                                WHOM
                                                                                                                                                                DO
                                                                                                                                                                WE
                                                                                                                                                                SHARE
                                                                                                                                                                YOUR
                                                                                                                                                                PERSONAL
                                                                                                                                                                INFORMATION?
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                "
                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                above),
                                                                                                                                                                in
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                        &nbsp;
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="forloop-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                &nbsp;and
                                                                                                                                                                other
                                                                                                                                                                countries.
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    If you are
                                                                                                                                                    a resident
                                                                                                                                                    in the
                                                                                                                                                    European
                                                                                                                                                    Economic
                                                                                                                                                    Area (EEA)
                                                                                                                                                    or United
                                                                                                                                                    Kingdom
                                                                                                                                                    (UK), then
                                                                                                                                                    these
                                                                                                                                                    countries
                                                                                                                                                    may not
                                                                                                                                                    necessarily
                                                                                                                                                    have data
                                                                                                                                                    protection
                                                                                                                                                    laws or
                                                                                                                                                    other
                                                                                                                                                    similar
                                                                                                                                                    laws as
                                                                                                                                                    comprehensive
                                                                                                                                                    as those
                                                                                                                                                    in your
                                                                                                                                                    country.
                                                                                                                                                    However,
                                                                                                                                                    we will
                                                                                                                                                    take all
                                                                                                                                                    necessary
                                                                                                                                                    measures
                                                                                                                                                    to protect
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    in
                                                                                                                                                    accordance
                                                                                                                                                    with this
                                                                                                                                                    privacy
                                                                                                                                                    notice and
                                                                                                                                                    applicable
                                                                                                                                                    law.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            color:
                                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                                            fontSize: 15
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color:
                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="inforetain"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    7.
                                                                                                                                                                    HOW
                                                                                                                                                                    LONG
                                                                                                                                                                    DO
                                                                                                                                                                    WE
                                                                                                                                                                    KEEP
                                                                                                                                                                    YOUR
                                                                                                                                                                    INFORMATION?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:&nbsp;
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        We keep
                                                                                                                                                        your
                                                                                                                                                        information
                                                                                                                                                        for as
                                                                                                                                                        long as
                                                                                                                                                        necessary
                                                                                                                                                        to{" "}
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        fulfill
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                        the
                                                                                                                                                        purposes
                                                                                                                                                        outlined
                                                                                                                                                        in this
                                                                                                                                                        privacy
                                                                                                                                                        notice
                                                                                                                                                        unless
                                                                                                                                                        otherwise
                                                                                                                                                        required
                                                                                                                                                        by law.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We will
                                                                                                                                                    only keep
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    for as
                                                                                                                                                    long as it
                                                                                                                                                    is
                                                                                                                                                    necessary
                                                                                                                                                    for the
                                                                                                                                                    purposes
                                                                                                                                                    set out in
                                                                                                                                                    this
                                                                                                                                                    privacy
                                                                                                                                                    notice,
                                                                                                                                                    unless a
                                                                                                                                                    longer
                                                                                                                                                    retention
                                                                                                                                                    period is
                                                                                                                                                    required
                                                                                                                                                    or
                                                                                                                                                    permitted
                                                                                                                                                    by law
                                                                                                                                                    (such as
                                                                                                                                                    tax,
                                                                                                                                                    accounting,
                                                                                                                                                    or other
                                                                                                                                                    legal
                                                                                                                                                    requirements).
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    When we
                                                                                                                                                    have no
                                                                                                                                                    ongoing
                                                                                                                                                    legitimate
                                                                                                                                                    business
                                                                                                                                                    need to
                                                                                                                                                    process
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    we will
                                                                                                                                                    either
                                                                                                                                                    delete or{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    anonymize
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    such
                                                                                                                                                    information,
                                                                                                                                                    or, if
                                                                                                                                                    this is
                                                                                                                                                    not
                                                                                                                                                    possible
                                                                                                                                                    (for
                                                                                                                                                    example,
                                                                                                                                                    because
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    has been
                                                                                                                                                    stored in
                                                                                                                                                    backup
                                                                                                                                                    archives),
                                                                                                                                                    then we
                                                                                                                                                    will
                                                                                                                                                    securely
                                                                                                                                                    store your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    and
                                                                                                                                                    isolate it
                                                                                                                                                    from any
                                                                                                                                                    further
                                                                                                                                                    processing
                                                                                                                                                    until
                                                                                                                                                    deletion
                                                                                                                                                    is
                                                                                                                                                    possible.
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="privacyrights"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    8.
                                                                                                                                                                    WHAT
                                                                                                                                                                    ARE
                                                                                                                                                                    YOUR
                                                                                                                                                                    PRIVACY
                                                                                                                                                                    RIGHTS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        &nbsp;
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <em>
                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                    </em>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                        In some
                                                                                                                                                        regions,
                                                                                                                                                        such as{" "}
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        the
                                                                                                                                                        European
                                                                                                                                                        Economic
                                                                                                                                                        Area
                                                                                                                                                        (EEA)
                                                                                                                                                        and
                                                                                                                                                        United
                                                                                                                                                        Kingdom
                                                                                                                                                        (UK)
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                        , you
                                                                                                                                                        have
                                                                                                                                                        rights
                                                                                                                                                        that
                                                                                                                                                        allow
                                                                                                                                                        you
                                                                                                                                                        greater
                                                                                                                                                        access
                                                                                                                                                        to and
                                                                                                                                                        control
                                                                                                                                                        over
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information.
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <em>
                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                    </em>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                            &nbsp;
                                                                                                                                                        </span>
                                                                                                                                                        You may
                                                                                                                                                        review,
                                                                                                                                                        change,
                                                                                                                                                        or
                                                                                                                                                        terminate
                                                                                                                                                        your
                                                                                                                                                        account
                                                                                                                                                        at any
                                                                                                                                                        time.
                                                                                                                                                    </em>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    In some
                                                                                                                                                    regions
                                                                                                                                                    (like{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    the EEA
                                                                                                                                                    and UK
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    ), you
                                                                                                                                                    have
                                                                                                                                                    certain
                                                                                                                                                    rights
                                                                                                                                                    under
                                                                                                                                                    applicable
                                                                                                                                                    data
                                                                                                                                                    protection
                                                                                                                                                    laws.
                                                                                                                                                    These may
                                                                                                                                                    include
                                                                                                                                                    the right
                                                                                                                                                    (i) to
                                                                                                                                                    request
                                                                                                                                                    access and
                                                                                                                                                    obtain a
                                                                                                                                                    copy of
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    (ii) to
                                                                                                                                                    request
                                                                                                                                                    rectification
                                                                                                                                                    or
                                                                                                                                                    erasure;
                                                                                                                                                    (iii) to
                                                                                                                                                    restrict
                                                                                                                                                    the
                                                                                                                                                    processing
                                                                                                                                                    of your
                                                                                                                                                    personal
                                                                                                                                                    information;
                                                                                                                                                    and (iv)
                                                                                                                                                    if
                                                                                                                                                    applicable,
                                                                                                                                                    to data
                                                                                                                                                    portability.
                                                                                                                                                    In certain
                                                                                                                                                    circumstances,
                                                                                                                                                    you may
                                                                                                                                                    also have
                                                                                                                                                    the right
                                                                                                                                                    to object
                                                                                                                                                    to the
                                                                                                                                                    processing
                                                                                                                                                    of your
                                                                                                                                                    personal
                                                                                                                                                    information.
                                                                                                                                                    You can
                                                                                                                                                    make such
                                                                                                                                                    a request
                                                                                                                                                    by
                                                                                                                                                    contacting
                                                                                                                                                    us by
                                                                                                                                                    using the
                                                                                                                                                    contact
                                                                                                                                                    details
                                                                                                                                                    provided
                                                                                                                                                    in the
                                                                                                                                                    section{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <a
                                                                                                                                            data-custom-class="link"
                                                                                                                                            href="#contact"
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        HOW CAN
                                                                                                                                                        YOU
                                                                                                                                                        CONTACT
                                                                                                                                                        US ABOUT
                                                                                                                                                        THIS
                                                                                                                                                        NOTICE?
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </a>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    below.
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We will
                                                                                                                                                    consider
                                                                                                                                                    and act
                                                                                                                                                    upon any
                                                                                                                                                    request in
                                                                                                                                                    accordance
                                                                                                                                                    with
                                                                                                                                                    applicable
                                                                                                                                                    data
                                                                                                                                                    protection
                                                                                                                                                    laws.
                                                                                                                                                </span>
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            fontSize: 15
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            &nbsp;
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    If you are
                                                                                                                                                    located in
                                                                                                                                                    the EEA or
                                                                                                                                                    UK and you
                                                                                                                                                    believe we
                                                                                                                                                    are
                                                                                                                                                    unlawfully
                                                                                                                                                    processing
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    you also
                                                                                                                                                    have the
                                                                                                                                                    right to
                                                                                                                                                    complain
                                                                                                                                                    to your{" "}
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(48, 48, 241)"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <a
                                                                                                                                                                            data-custom-class="link"
                                                                                                                                                                            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                                                                                                            rel="noopener noreferrer"
                                                                                                                                                                            target="_blank"
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                Member
                                                                                                                                                                                State
                                                                                                                                                                                data
                                                                                                                                                                                protection
                                                                                                                                                                                authority
                                                                                                                                                                            </span>
                                                                                                                                                                        </a>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>{" "}
                                                                                                                                                    or&nbsp;
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <a
                                                                                                                                            href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                                                                                                            rel="noopener noreferrer"
                                                                                                                                            target="_blank"
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="link">
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            UK
                                                                                                                                                            data
                                                                                                                                                            protection
                                                                                                                                                            authority
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </a>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    If you are
                                                                                                                                                    located in
                                                                                                                                                    Switzerland,
                                                                                                                                                    you may
                                                                                                                                                    contact
                                                                                                                                                    the{" "}
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(48, 48, 241)"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <a
                                                                                                                                                                                data-custom-class="link"
                                                                                                                                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                                                                                                                rel="noopener noreferrer"
                                                                                                                                                                                target="_blank"
                                                                                                                                                                            >
                                                                                                                                                                                Federal
                                                                                                                                                                                Data
                                                                                                                                                                                Protection
                                                                                                                                                                                and
                                                                                                                                                                                Information
                                                                                                                                                                                Commissioner
                                                                                                                                                                            </a>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="withdrawconsent"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <u>
                                                                                                                                                            Withdrawing
                                                                                                                                                            your
                                                                                                                                                            consent:
                                                                                                                                                        </u>
                                                                                                                                                    </strong>{" "}
                                                                                                                                                    If we are
                                                                                                                                                    relying on
                                                                                                                                                    your
                                                                                                                                                    consent to
                                                                                                                                                    process
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information,
                                                                                                                                                    <bdt className="block-component" />{" "}
                                                                                                                                                    which may
                                                                                                                                                    be express
                                                                                                                                                    and/or
                                                                                                                                                    implied
                                                                                                                                                    consent
                                                                                                                                                    depending
                                                                                                                                                    on the
                                                                                                                                                    applicable
                                                                                                                                                    law,
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    you have
                                                                                                                                                    the right
                                                                                                                                                    to
                                                                                                                                                    withdraw
                                                                                                                                                    your
                                                                                                                                                    consent at
                                                                                                                                                    any time.
                                                                                                                                                    You can
                                                                                                                                                    withdraw
                                                                                                                                                    your
                                                                                                                                                    consent at
                                                                                                                                                    any time
                                                                                                                                                    by
                                                                                                                                                    contacting
                                                                                                                                                    us by
                                                                                                                                                    using the
                                                                                                                                                    contact
                                                                                                                                                    details
                                                                                                                                                    provided
                                                                                                                                                    in the
                                                                                                                                                    section{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <a
                                                                                                                                            data-custom-class="link"
                                                                                                                                            href="#contact"
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        HOW CAN
                                                                                                                                                        YOU
                                                                                                                                                        CONTACT
                                                                                                                                                        US ABOUT
                                                                                                                                                        THIS
                                                                                                                                                        NOTICE?
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </a>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    below
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    .
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                However,
                                                                                                                                                please note
                                                                                                                                                that this
                                                                                                                                                will not
                                                                                                                                                affect the
                                                                                                                                                lawfulness
                                                                                                                                                of the
                                                                                                                                                processing
                                                                                                                                                before its
                                                                                                                                                withdrawal
                                                                                                                                                nor,
                                                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                                                when
                                                                                                                                                applicable
                                                                                                                                                law allows,
                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                will it
                                                                                                                                                affect the
                                                                                                                                                processing
                                                                                                                                                of your
                                                                                                                                                personal
                                                                                                                                                information
                                                                                                                                                conducted in
                                                                                                                                                reliance on
                                                                                                                                                lawful
                                                                                                                                                processing
                                                                                                                                                grounds
                                                                                                                                                other than
                                                                                                                                                consent.
                                                                                                                                                <bdt className="block-component" />
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        color:
                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                fontSize: 15
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color:
                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                If you have
                                                                                                                                                questions or
                                                                                                                                                comments
                                                                                                                                                about your
                                                                                                                                                privacy
                                                                                                                                                rights, you
                                                                                                                                                may email us
                                                                                                                                                at{" "}
                                                                                                                                                <bdt className="question">
                                                                                                                                                    mail@myinstants.org
                                                                                                                                                </bdt>
                                                                                                                                                .
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="DNT"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    9.
                                                                                                                                                                    CONTROLS
                                                                                                                                                                    FOR
                                                                                                                                                                    DO-NOT-TRACK
                                                                                                                                                                    FEATURES
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    Most web
                                                                                                                                                    browsers
                                                                                                                                                    and some
                                                                                                                                                    mobile
                                                                                                                                                    operating
                                                                                                                                                    systems
                                                                                                                                                    and mobile
                                                                                                                                                    applications
                                                                                                                                                    include a
                                                                                                                                                    Do-Not-Track
                                                                                                                                                    (
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "DNT"
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    ) feature
                                                                                                                                                    or setting
                                                                                                                                                    you can
                                                                                                                                                    activate
                                                                                                                                                    to signal
                                                                                                                                                    your
                                                                                                                                                    privacy
                                                                                                                                                    preference
                                                                                                                                                    not to
                                                                                                                                                    have data
                                                                                                                                                    about your
                                                                                                                                                    online
                                                                                                                                                    browsing
                                                                                                                                                    activities
                                                                                                                                                    monitored
                                                                                                                                                    and
                                                                                                                                                    collected.
                                                                                                                                                    At this
                                                                                                                                                    stage no
                                                                                                                                                    uniform
                                                                                                                                                    technology
                                                                                                                                                    standard
                                                                                                                                                    for{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    recognizing
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    and
                                                                                                                                                    implementing
                                                                                                                                                    DNT
                                                                                                                                                    signals
                                                                                                                                                    has been{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    finalized
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    . As such,
                                                                                                                                                    we do not
                                                                                                                                                    currently
                                                                                                                                                    respond to
                                                                                                                                                    DNT
                                                                                                                                                    browser
                                                                                                                                                    signals or
                                                                                                                                                    any other
                                                                                                                                                    mechanism
                                                                                                                                                    that
                                                                                                                                                    automatically
                                                                                                                                                    communicates
                                                                                                                                                    your
                                                                                                                                                    choice not
                                                                                                                                                    to be
                                                                                                                                                    tracked
                                                                                                                                                    online. If
                                                                                                                                                    a standard
                                                                                                                                                    for online
                                                                                                                                                    tracking
                                                                                                                                                    is adopted
                                                                                                                                                    that we
                                                                                                                                                    must
                                                                                                                                                    follow in
                                                                                                                                                    the
                                                                                                                                                    future, we
                                                                                                                                                    will
                                                                                                                                                    inform you
                                                                                                                                                    about that
                                                                                                                                                    practice
                                                                                                                                                    in a
                                                                                                                                                    revised
                                                                                                                                                    version of
                                                                                                                                                    this
                                                                                                                                                    privacy
                                                                                                                                                    notice.
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        id="caresidents"
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15,
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            id="control"
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <strong>
                                                                                                                                                                <span data-custom-class="heading_1">
                                                                                                                                                                    10.
                                                                                                                                                                    DO
                                                                                                                                                                    CALIFORNIA
                                                                                                                                                                    RESIDENTS
                                                                                                                                                                    HAVE
                                                                                                                                                                    SPECIFIC
                                                                                                                                                                    PRIVACY
                                                                                                                                                                    RIGHTS?
                                                                                                                                                                </span>
                                                                                                                                                            </strong>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        <em>
                                                                                                                                                            In
                                                                                                                                                            Short:&nbsp;
                                                                                                                                                        </em>
                                                                                                                                                    </strong>
                                                                                                                                                    <em>
                                                                                                                                                        Yes, if
                                                                                                                                                        you are
                                                                                                                                                        a
                                                                                                                                                        resident
                                                                                                                                                        of
                                                                                                                                                        California,
                                                                                                                                                        you are
                                                                                                                                                        granted
                                                                                                                                                        specific
                                                                                                                                                        rights
                                                                                                                                                        regarding
                                                                                                                                                        access
                                                                                                                                                        to your
                                                                                                                                                        personal
                                                                                                                                                        information.
                                                                                                                                                    </em>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    California
                                                                                                                                                    Civil Code
                                                                                                                                                    Section
                                                                                                                                                    1798.83,
                                                                                                                                                    also known
                                                                                                                                                    as the{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "Shine The
                                                                                                                                                    Light"
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    law,
                                                                                                                                                    permits
                                                                                                                                                    our users
                                                                                                                                                    who are
                                                                                                                                                    California
                                                                                                                                                    residents
                                                                                                                                                    to request
                                                                                                                                                    and obtain
                                                                                                                                                    from us,
                                                                                                                                                    once a
                                                                                                                                                    year and
                                                                                                                                                    free of
                                                                                                                                                    charge,
                                                                                                                                                    information
                                                                                                                                                    about
                                                                                                                                                    categories
                                                                                                                                                    of
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    (if any)
                                                                                                                                                    we
                                                                                                                                                    disclosed
                                                                                                                                                    to third
                                                                                                                                                    parties
                                                                                                                                                    for direct
                                                                                                                                                    marketing
                                                                                                                                                    purposes
                                                                                                                                                    and the
                                                                                                                                                    names and
                                                                                                                                                    addresses
                                                                                                                                                    of all
                                                                                                                                                    third
                                                                                                                                                    parties
                                                                                                                                                    with which
                                                                                                                                                    we shared
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    in the
                                                                                                                                                    immediately
                                                                                                                                                    preceding
                                                                                                                                                    calendar
                                                                                                                                                    year. If
                                                                                                                                                    you are a
                                                                                                                                                    California
                                                                                                                                                    resident
                                                                                                                                                    and would
                                                                                                                                                    like to
                                                                                                                                                    make such
                                                                                                                                                    a request,
                                                                                                                                                    please
                                                                                                                                                    submit
                                                                                                                                                    your
                                                                                                                                                    request in
                                                                                                                                                    writing to
                                                                                                                                                    us using
                                                                                                                                                    the
                                                                                                                                                    contact
                                                                                                                                                    information
                                                                                                                                                    provided
                                                                                                                                                    below.
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    If you are
                                                                                                                                                    under 18
                                                                                                                                                    years of
                                                                                                                                                    age,
                                                                                                                                                    reside in
                                                                                                                                                    California,
                                                                                                                                                    and have a
                                                                                                                                                    registered
                                                                                                                                                    account
                                                                                                                                                    with
                                                                                                                                                    Services,
                                                                                                                                                    you have
                                                                                                                                                    the right
                                                                                                                                                    to request
                                                                                                                                                    removal of
                                                                                                                                                    unwanted
                                                                                                                                                    data that
                                                                                                                                                    you
                                                                                                                                                    publicly
                                                                                                                                                    post on
                                                                                                                                                    the
                                                                                                                                                    Services.
                                                                                                                                                    To request
                                                                                                                                                    removal of
                                                                                                                                                    such data,
                                                                                                                                                    please
                                                                                                                                                    contact us
                                                                                                                                                    using the
                                                                                                                                                    contact
                                                                                                                                                    information
                                                                                                                                                    provided
                                                                                                                                                    below and
                                                                                                                                                    include
                                                                                                                                                    the email
                                                                                                                                                    address
                                                                                                                                                    associated
                                                                                                                                                    with your
                                                                                                                                                    account
                                                                                                                                                    and a
                                                                                                                                                    statement
                                                                                                                                                    that you
                                                                                                                                                    reside in
                                                                                                                                                    California.
                                                                                                                                                    We will
                                                                                                                                                    make sure
                                                                                                                                                    the data
                                                                                                                                                    is not
                                                                                                                                                    publicly
                                                                                                                                                    displayed
                                                                                                                                                    on the
                                                                                                                                                    Services,
                                                                                                                                                    but please
                                                                                                                                                    be aware
                                                                                                                                                    that the
                                                                                                                                                    data may
                                                                                                                                                    not be
                                                                                                                                                    completely
                                                                                                                                                    or
                                                                                                                                                    comprehensively
                                                                                                                                                    removed
                                                                                                                                                    from all
                                                                                                                                                    our
                                                                                                                                                    systems
                                                                                                                                                    (e.g.
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    ,
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    backups,
                                                                                                                                                    etc.).
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            data-custom-class="heading_2"
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <strong>
                                                                                                                                                    CCPA
                                                                                                                                                    Privacy
                                                                                                                                                    Notice
                                                                                                                                                </strong>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <div>
                                                                                                                                            <br />
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    "1.5"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        color:
                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    The
                                                                                                                                                                    California
                                                                                                                                                                    Code
                                                                                                                                                                    of
                                                                                                                                                                    Regulations
                                                                                                                                                                    defines
                                                                                                                                                                    a{" "}
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    "resident"
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                    as:
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5",
                                                                                                                                            marginLeft: 20
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    (1) every
                                                                                                                                                    individual
                                                                                                                                                    who is in
                                                                                                                                                    the State
                                                                                                                                                    of
                                                                                                                                                    California
                                                                                                                                                    for other
                                                                                                                                                    than a
                                                                                                                                                    temporary
                                                                                                                                                    or
                                                                                                                                                    transitory
                                                                                                                                                    purpose
                                                                                                                                                    and
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5",
                                                                                                                                            marginLeft: 20
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    (2) every
                                                                                                                                                    individual
                                                                                                                                                    who is
                                                                                                                                                    domiciled
                                                                                                                                                    in the
                                                                                                                                                    State of
                                                                                                                                                    California
                                                                                                                                                    who is
                                                                                                                                                    outside
                                                                                                                                                    the State
                                                                                                                                                    of
                                                                                                                                                    California
                                                                                                                                                    for a
                                                                                                                                                    temporary
                                                                                                                                                    or
                                                                                                                                                    transitory
                                                                                                                                                    purpose
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    All other
                                                                                                                                                    individuals
                                                                                                                                                    are
                                                                                                                                                    defined as{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "non-residents."
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    If this
                                                                                                                                                    definition
                                                                                                                                                    of{" "}
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                    "resident"
                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                    applies to
                                                                                                                                                    you, we
                                                                                                                                                    must
                                                                                                                                                    adhere to
                                                                                                                                                    certain
                                                                                                                                                    rights and
                                                                                                                                                    obligations
                                                                                                                                                    regarding
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information.
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <strong>
                                                                                                                                                        What
                                                                                                                                                        categories
                                                                                                                                                        of
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        do we
                                                                                                                                                        collect?
                                                                                                                                                    </strong>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                                color:
                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                    color:
                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    We have
                                                                                                                                                    collected
                                                                                                                                                    the
                                                                                                                                                    following
                                                                                                                                                    categories
                                                                                                                                                    of
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    in the
                                                                                                                                                    past
                                                                                                                                                    twelve
                                                                                                                                                    (12)
                                                                                                                                                    months:
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <table
                                                                                                                                        style={{
                                                                                                                                            width: "100%"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <strong>
                                                                                                                                                                    Category
                                                                                                                                                                </strong>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <strong>
                                                                                                                                                                    Examples
                                                                                                                                                                </strong>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <strong>
                                                                                                                                                                    Collected
                                                                                                                                                                </strong>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    A.
                                                                                                                                                                    Identifiers
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Contact
                                                                                                                                                                    details,
                                                                                                                                                                    such
                                                                                                                                                                    as
                                                                                                                                                                    real
                                                                                                                                                                    name,
                                                                                                                                                                    alias,
                                                                                                                                                                    postal
                                                                                                                                                                    address,
                                                                                                                                                                    telephone
                                                                                                                                                                    or
                                                                                                                                                                    mobile
                                                                                                                                                                    contact
                                                                                                                                                                    number,
                                                                                                                                                                    unique
                                                                                                                                                                    personal
                                                                                                                                                                    identifier,
                                                                                                                                                                    online
                                                                                                                                                                    identifier,
                                                                                                                                                                    Internet
                                                                                                                                                                    Protocol
                                                                                                                                                                    address,
                                                                                                                                                                    email
                                                                                                                                                                    address,
                                                                                                                                                                    and
                                                                                                                                                                    account
                                                                                                                                                                    name
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        verticalAlign:
                                                                                                                                                            "middle",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    B.
                                                                                                                                                                    Personal
                                                                                                                                                                    information
                                                                                                                                                                    categories
                                                                                                                                                                    listed
                                                                                                                                                                    in
                                                                                                                                                                    the
                                                                                                                                                                    California
                                                                                                                                                                    Customer
                                                                                                                                                                    Records
                                                                                                                                                                    statute
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Name,
                                                                                                                                                                    contact
                                                                                                                                                                    information,
                                                                                                                                                                    education,
                                                                                                                                                                    employment,
                                                                                                                                                                    employment
                                                                                                                                                                    history,
                                                                                                                                                                    and
                                                                                                                                                                    financial
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    C.
                                                                                                                                                                    Protected
                                                                                                                                                                    classification
                                                                                                                                                                    characteristics
                                                                                                                                                                    under
                                                                                                                                                                    California
                                                                                                                                                                    or
                                                                                                                                                                    federal
                                                                                                                                                                    law
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Gender
                                                                                                                                                                    and
                                                                                                                                                                    date
                                                                                                                                                                    of
                                                                                                                                                                    birth
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    D.
                                                                                                                                                                    Commercial
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Transaction
                                                                                                                                                                    information,
                                                                                                                                                                    purchase
                                                                                                                                                                    history,
                                                                                                                                                                    financial
                                                                                                                                                                    details,
                                                                                                                                                                    and
                                                                                                                                                                    payment
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    E.
                                                                                                                                                                    Biometric
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Fingerprints
                                                                                                                                                                    and
                                                                                                                                                                    voiceprints
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    F.
                                                                                                                                                                    Internet
                                                                                                                                                                    or
                                                                                                                                                                    other
                                                                                                                                                                    similar
                                                                                                                                                                    network
                                                                                                                                                                    activity
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Browsing
                                                                                                                                                                    history,
                                                                                                                                                                    search
                                                                                                                                                                    history,
                                                                                                                                                                    online{" "}
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    behavior
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                    ,
                                                                                                                                                                    interest
                                                                                                                                                                    data,
                                                                                                                                                                    and
                                                                                                                                                                    interactions
                                                                                                                                                                    with
                                                                                                                                                                    our
                                                                                                                                                                    and
                                                                                                                                                                    other
                                                                                                                                                                    websites,
                                                                                                                                                                    applications,
                                                                                                                                                                    systems,
                                                                                                                                                                    and
                                                                                                                                                                    advertisements
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    G.
                                                                                                                                                                    Geolocation
                                                                                                                                                                    data
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Device
                                                                                                                                                                    location
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    H.
                                                                                                                                                                    Audio,
                                                                                                                                                                    electronic,
                                                                                                                                                                    visual,
                                                                                                                                                                    thermal,
                                                                                                                                                                    olfactory,
                                                                                                                                                                    or
                                                                                                                                                                    similar
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Images
                                                                                                                                                                    and
                                                                                                                                                                    audio,
                                                                                                                                                                    video
                                                                                                                                                                    or
                                                                                                                                                                    call
                                                                                                                                                                    recordings
                                                                                                                                                                    created
                                                                                                                                                                    in
                                                                                                                                                                    connection
                                                                                                                                                                    with
                                                                                                                                                                    our
                                                                                                                                                                    business
                                                                                                                                                                    activities
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    YES
                                                                                                                                                                    <bdt className="else-block" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%",
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    I.
                                                                                                                                                                    Professional
                                                                                                                                                                    or
                                                                                                                                                                    employment-related
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Business
                                                                                                                                                                    contact
                                                                                                                                                                    details
                                                                                                                                                                    in
                                                                                                                                                                    order
                                                                                                                                                                    to
                                                                                                                                                                    provide
                                                                                                                                                                    you
                                                                                                                                                                    our
                                                                                                                                                                    Services
                                                                                                                                                                    at
                                                                                                                                                                    a
                                                                                                                                                                    business
                                                                                                                                                                    level
                                                                                                                                                                    or
                                                                                                                                                                    job
                                                                                                                                                                    title,
                                                                                                                                                                    work
                                                                                                                                                                    history,
                                                                                                                                                                    and
                                                                                                                                                                    professional
                                                                                                                                                                    qualifications
                                                                                                                                                                    if
                                                                                                                                                                    you
                                                                                                                                                                    apply
                                                                                                                                                                    for
                                                                                                                                                                    a
                                                                                                                                                                    job
                                                                                                                                                                    with
                                                                                                                                                                    us
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    J.
                                                                                                                                                                    Education
                                                                                                                                                                    Information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Student
                                                                                                                                                                    records
                                                                                                                                                                    and
                                                                                                                                                                    directory
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderWidth: 1,
                                                                                                                                                        borderColor:
                                                                                                                                                            "black",
                                                                                                                                                        borderStyle:
                                                                                                                                                            "solid",
                                                                                                                                                        width:
                                                                                                                                                            "33.8274%"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    K.
                                                                                                                                                                    Inferences
                                                                                                                                                                    drawn
                                                                                                                                                                    from
                                                                                                                                                                    other
                                                                                                                                                                    personal
                                                                                                                                                                    information
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderBottom:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        width:
                                                                                                                                                            "51.4385%"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    Inferences
                                                                                                                                                                    drawn
                                                                                                                                                                    from
                                                                                                                                                                    any
                                                                                                                                                                    of
                                                                                                                                                                    the
                                                                                                                                                                    collected
                                                                                                                                                                    personal
                                                                                                                                                                    information
                                                                                                                                                                    listed
                                                                                                                                                                    above
                                                                                                                                                                    to
                                                                                                                                                                    create
                                                                                                                                                                    a
                                                                                                                                                                    profile
                                                                                                                                                                    or
                                                                                                                                                                    summary
                                                                                                                                                                    about,
                                                                                                                                                                    for
                                                                                                                                                                    example,
                                                                                                                                                                    an
                                                                                                                                                                    individual’s
                                                                                                                                                                    preferences
                                                                                                                                                                    and
                                                                                                                                                                    characteristics
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderBottom:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderTop:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        width:
                                                                                                                                                            "14.9084%"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize: 15,
                                                                                                                                                                color:
                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                    color:
                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                    NO
                                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                                    </bdt>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <br />
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderLeft:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderBottom:
                                                                                                                                                            "1px solid black"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        L.
                                                                                                                                                        Sensitive
                                                                                                                                                        Personal
                                                                                                                                                        Information
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    style={{
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderBottom:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <bdt className="block-component">
                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                    </bdt>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                    style={{
                                                                                                                                                        borderRight:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        borderBottom:
                                                                                                                                                            "1px solid black",
                                                                                                                                                        textAlign:
                                                                                                                                                            "center",
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                    <bdt className="block-component">
                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                    </bdt>
                                                                                                                                                    NO
                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                    </bdt>
                                                                                                                                                    <br />
                                                                                                                                                    <br />
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component" />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <br />
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                        </bdt>
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            We will use
                                                                                                                                            and retain the
                                                                                                                                            collected
                                                                                                                                            personal
                                                                                                                                            information as
                                                                                                                                            needed to
                                                                                                                                            provide the
                                                                                                                                            Services or
                                                                                                                                            for:
                                                                                                                                            <bdt className="block-component" />
                                                                                                                                        </span>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    "1.5"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <bdt className="block-component" />
                                                                                                                                            </span>
                                                                                                                                            <div
                                                                                                                                                style={{
                                                                                                                                                    lineHeight:
                                                                                                                                                        "1.5"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                    </span>
                                                                                                                                                    <div
                                                                                                                                                        style={{
                                                                                                                                                            lineHeight:
                                                                                                                                                                "1.5"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                        </span>
                                                                                                                                                        <div
                                                                                                                                                            style={{
                                                                                                                                                                lineHeight:
                                                                                                                                                                    "1.5"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                            </span>
                                                                                                                                                            <div
                                                                                                                                                                style={{
                                                                                                                                                                    lineHeight:
                                                                                                                                                                        "1.5"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                </span>
                                                                                                                                                                <div
                                                                                                                                                                    style={{
                                                                                                                                                                        lineHeight:
                                                                                                                                                                            "1.5"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                </div>
                                                                                                                                                                <ul>
                                                                                                                                                                    <li
                                                                                                                                                                        style={{
                                                                                                                                                                            lineHeight:
                                                                                                                                                                                "1.5"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                            Category
                                                                                                                                                                            H
                                                                                                                                                                            -{" "}
                                                                                                                                                                            <bdt className="question">
                                                                                                                                                                                6
                                                                                                                                                                                months
                                                                                                                                                                            </bdt>
                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                        </span>
                                                                                                                                                                    </li>
                                                                                                                                                                </ul>
                                                                                                                                                                <div
                                                                                                                                                                    style={{
                                                                                                                                                                        lineHeight:
                                                                                                                                                                            "1.5"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                    </span>
                                                                                                                                                                    <div
                                                                                                                                                                        style={{
                                                                                                                                                                            lineHeight:
                                                                                                                                                                                "1.5"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                        </span>
                                                                                                                                                                        <div
                                                                                                                                                                            style={{
                                                                                                                                                                                lineHeight:
                                                                                                                                                                                    "1.5"
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                            </span>
                                                                                                                                                                            <div
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        "1.5"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                </span>
                                                                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                    <span data-custom-class="body_text" />
                                                                                                                                                                                </bdt>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                We
                                                                                                                                                                                                may
                                                                                                                                                                                                also
                                                                                                                                                                                                collect
                                                                                                                                                                                                other
                                                                                                                                                                                                personal
                                                                                                                                                                                                information
                                                                                                                                                                                                outside
                                                                                                                                                                                                of
                                                                                                                                                                                                these
                                                                                                                                                                                                categories
                                                                                                                                                                                                through
                                                                                                                                                                                                instances
                                                                                                                                                                                                where
                                                                                                                                                                                                you
                                                                                                                                                                                                interact
                                                                                                                                                                                                with
                                                                                                                                                                                                us
                                                                                                                                                                                                in
                                                                                                                                                                                                person,
                                                                                                                                                                                                online,
                                                                                                                                                                                                or
                                                                                                                                                                                                by
                                                                                                                                                                                                phone
                                                                                                                                                                                                or
                                                                                                                                                                                                mail
                                                                                                                                                                                                in
                                                                                                                                                                                                the
                                                                                                                                                                                                context
                                                                                                                                                                                                of:
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    Receiving
                                                                                                                                                                                                    help
                                                                                                                                                                                                    through
                                                                                                                                                                                                    our
                                                                                                                                                                                                    customer
                                                                                                                                                                                                    support
                                                                                                                                                                                                    channels;
                                                                                                                                                                                                </span>
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                            color:
                                                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    Participation
                                                                                                                                                                                                    in
                                                                                                                                                                                                    customer
                                                                                                                                                                                                    surveys
                                                                                                                                                                                                    or
                                                                                                                                                                                                    contests;
                                                                                                                                                                                                    and
                                                                                                                                                                                                </span>
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                            color:
                                                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    Facilitation
                                                                                                                                                                                                    in
                                                                                                                                                                                                    the
                                                                                                                                                                                                    delivery
                                                                                                                                                                                                    of
                                                                                                                                                                                                    our
                                                                                                                                                                                                    Services
                                                                                                                                                                                                    and
                                                                                                                                                                                                    to
                                                                                                                                                                                                    respond
                                                                                                                                                                                                    to
                                                                                                                                                                                                    your
                                                                                                                                                                                                    inquiries.
                                                                                                                                                                                                </span>
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                            color:
                                                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <strong>
                                                                                                                                                                                                    How
                                                                                                                                                                                                    do
                                                                                                                                                                                                    we
                                                                                                                                                                                                    use
                                                                                                                                                                                                    and
                                                                                                                                                                                                    share
                                                                                                                                                                                                    your
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information?
                                                                                                                                                                                                </strong>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            More
                                                                                                                                                                                                            information
                                                                                                                                                                                                            about
                                                                                                                                                                                                            our
                                                                                                                                                                                                            data
                                                                                                                                                                                                            collection
                                                                                                                                                                                                            and
                                                                                                                                                                                                            sharing
                                                                                                                                                                                                            practices
                                                                                                                                                                                                            can
                                                                                                                                                                                                            be
                                                                                                                                                                                                            found
                                                                                                                                                                                                            in
                                                                                                                                                                                                            this
                                                                                                                                                                                                            privacy
                                                                                                                                                                                                            notice
                                                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                                                            .
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                You
                                                                                                                                                                                                may
                                                                                                                                                                                                contact
                                                                                                                                                                                                us{" "}
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                        <bdt className="block-component">
                                                                                                                                                                                                            <span data-custom-class="body_text" />
                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        or
                                                                                                                                                                                        by
                                                                                                                                                                                        referring
                                                                                                                                                                                        to
                                                                                                                                                                                        the
                                                                                                                                                                                        contact
                                                                                                                                                                                        details
                                                                                                                                                                                        at
                                                                                                                                                                                        the
                                                                                                                                                                                        bottom
                                                                                                                                                                                        of
                                                                                                                                                                                        this
                                                                                                                                                                                        document.
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                If
                                                                                                                                                                                                you
                                                                                                                                                                                                are
                                                                                                                                                                                                using
                                                                                                                                                                                                an{" "}
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                authorized
                                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                agent
                                                                                                                                                                                                to
                                                                                                                                                                                                exercise
                                                                                                                                                                                                your
                                                                                                                                                                                                right
                                                                                                                                                                                                to
                                                                                                                                                                                                opt
                                                                                                                                                                                                out
                                                                                                                                                                                                we
                                                                                                                                                                                                may
                                                                                                                                                                                                deny
                                                                                                                                                                                                a
                                                                                                                                                                                                request
                                                                                                                                                                                                if
                                                                                                                                                                                                the{" "}
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                authorized
                                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                agent
                                                                                                                                                                                                does
                                                                                                                                                                                                not
                                                                                                                                                                                                submit
                                                                                                                                                                                                proof
                                                                                                                                                                                                that
                                                                                                                                                                                                they
                                                                                                                                                                                                have
                                                                                                                                                                                                been
                                                                                                                                                                                                validly{" "}
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                authorized
                                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                to
                                                                                                                                                                                                act
                                                                                                                                                                                                on
                                                                                                                                                                                                your
                                                                                                                                                                                                behalf.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <strong>
                                                                                                                                                                                                    Will
                                                                                                                                                                                                    your
                                                                                                                                                                                                    information
                                                                                                                                                                                                    be
                                                                                                                                                                                                    shared
                                                                                                                                                                                                    with
                                                                                                                                                                                                    anyone
                                                                                                                                                                                                    else?
                                                                                                                                                                                                </strong>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                We
                                                                                                                                                                                                may
                                                                                                                                                                                                disclose
                                                                                                                                                                                                your
                                                                                                                                                                                                personal
                                                                                                                                                                                                information
                                                                                                                                                                                                with
                                                                                                                                                                                                our
                                                                                                                                                                                                service
                                                                                                                                                                                                providers
                                                                                                                                                                                                pursuant
                                                                                                                                                                                                to
                                                                                                                                                                                                a
                                                                                                                                                                                                written
                                                                                                                                                                                                contract
                                                                                                                                                                                                between
                                                                                                                                                                                                us
                                                                                                                                                                                                and
                                                                                                                                                                                                each
                                                                                                                                                                                                service
                                                                                                                                                                                                provider.
                                                                                                                                                                                                Each
                                                                                                                                                                                                service
                                                                                                                                                                                                provider
                                                                                                                                                                                                is
                                                                                                                                                                                                a
                                                                                                                                                                                                for-profit
                                                                                                                                                                                                entity
                                                                                                                                                                                                that
                                                                                                                                                                                                processes
                                                                                                                                                                                                the
                                                                                                                                                                                                information
                                                                                                                                                                                                on
                                                                                                                                                                                                our
                                                                                                                                                                                                behalf,
                                                                                                                                                                                                following
                                                                                                                                                                                                the
                                                                                                                                                                                                same
                                                                                                                                                                                                strict
                                                                                                                                                                                                privacy
                                                                                                                                                                                                protection
                                                                                                                                                                                                obligations
                                                                                                                                                                                                mandated
                                                                                                                                                                                                by
                                                                                                                                                                                                the
                                                                                                                                                                                                CCPA.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                We
                                                                                                                                                                                                may
                                                                                                                                                                                                use
                                                                                                                                                                                                your
                                                                                                                                                                                                personal
                                                                                                                                                                                                information
                                                                                                                                                                                                for
                                                                                                                                                                                                our
                                                                                                                                                                                                own
                                                                                                                                                                                                business
                                                                                                                                                                                                purposes,
                                                                                                                                                                                                such
                                                                                                                                                                                                as
                                                                                                                                                                                                for
                                                                                                                                                                                                undertaking
                                                                                                                                                                                                internal
                                                                                                                                                                                                research
                                                                                                                                                                                                for
                                                                                                                                                                                                technological
                                                                                                                                                                                                development
                                                                                                                                                                                                and
                                                                                                                                                                                                demonstration.
                                                                                                                                                                                                This
                                                                                                                                                                                                is
                                                                                                                                                                                                not
                                                                                                                                                                                                considered
                                                                                                                                                                                                to
                                                                                                                                                                                                be{" "}
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                "selling"
                                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                of
                                                                                                                                                                                                your
                                                                                                                                                                                                personal
                                                                                                                                                                                                information.
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <bdt className="question">
                                                                                                                                                                                                    My
                                                                                                                                                                                                    Instants
                                                                                                                                                                                                </bdt>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                &nbsp;has
                                                                                                                                                                                                not
                                                                                                                                                                                                disclosed,
                                                                                                                                                                                                sold,
                                                                                                                                                                                                or
                                                                                                                                                                                                shared
                                                                                                                                                                                                any
                                                                                                                                                                                                personal
                                                                                                                                                                                                information
                                                                                                                                                                                                to
                                                                                                                                                                                                third
                                                                                                                                                                                                parties
                                                                                                                                                                                                for
                                                                                                                                                                                                a
                                                                                                                                                                                                business
                                                                                                                                                                                                or
                                                                                                                                                                                                commercial
                                                                                                                                                                                                purpose
                                                                                                                                                                                                in
                                                                                                                                                                                                the
                                                                                                                                                                                                preceding
                                                                                                                                                                                                twelve
                                                                                                                                                                                                (12)
                                                                                                                                                                                                months.{" "}
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <bdt className="question">
                                                                                                                                                                                                                My
                                                                                                                                                                                                                Instants
                                                                                                                                                                                                            </bdt>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                                </span>
                                                                                                                                                                                                will
                                                                                                                                                                                                not
                                                                                                                                                                                                sell
                                                                                                                                                                                                or
                                                                                                                                                                                                share
                                                                                                                                                                                                personal
                                                                                                                                                                                                information
                                                                                                                                                                                                in
                                                                                                                                                                                                the
                                                                                                                                                                                                future
                                                                                                                                                                                                belonging
                                                                                                                                                                                                to
                                                                                                                                                                                                website
                                                                                                                                                                                                visitors,
                                                                                                                                                                                                users,
                                                                                                                                                                                                and
                                                                                                                                                                                                other
                                                                                                                                                                                                consumers.
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color:
                                                                                                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <strong>
                                                                                                                                                                                                    Your
                                                                                                                                                                                                    rights
                                                                                                                                                                                                    with
                                                                                                                                                                                                    respect
                                                                                                                                                                                                    to
                                                                                                                                                                                                    your
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    data
                                                                                                                                                                                                </strong>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <u>
                                                                                                                                                                                                    Right
                                                                                                                                                                                                    to
                                                                                                                                                                                                    request
                                                                                                                                                                                                    deletion
                                                                                                                                                                                                    of
                                                                                                                                                                                                    the
                                                                                                                                                                                                    data
                                                                                                                                                                                                    —
                                                                                                                                                                                                    Request
                                                                                                                                                                                                    to
                                                                                                                                                                                                    delete
                                                                                                                                                                                                </u>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                You
                                                                                                                                                                                                can
                                                                                                                                                                                                ask
                                                                                                                                                                                                for
                                                                                                                                                                                                the
                                                                                                                                                                                                deletion
                                                                                                                                                                                                of
                                                                                                                                                                                                your
                                                                                                                                                                                                personal
                                                                                                                                                                                                information.
                                                                                                                                                                                                If
                                                                                                                                                                                                you
                                                                                                                                                                                                ask
                                                                                                                                                                                                us
                                                                                                                                                                                                to
                                                                                                                                                                                                delete
                                                                                                                                                                                                your
                                                                                                                                                                                                personal
                                                                                                                                                                                                information,
                                                                                                                                                                                                we
                                                                                                                                                                                                will
                                                                                                                                                                                                respect
                                                                                                                                                                                                your
                                                                                                                                                                                                request
                                                                                                                                                                                                and
                                                                                                                                                                                                delete
                                                                                                                                                                                                your
                                                                                                                                                                                                personal
                                                                                                                                                                                                information,
                                                                                                                                                                                                subject
                                                                                                                                                                                                to
                                                                                                                                                                                                certain
                                                                                                                                                                                                exceptions
                                                                                                                                                                                                provided
                                                                                                                                                                                                by
                                                                                                                                                                                                law,
                                                                                                                                                                                                such
                                                                                                                                                                                                as
                                                                                                                                                                                                (but
                                                                                                                                                                                                not
                                                                                                                                                                                                limited
                                                                                                                                                                                                to)
                                                                                                                                                                                                the
                                                                                                                                                                                                exercise
                                                                                                                                                                                                by
                                                                                                                                                                                                another
                                                                                                                                                                                                consumer
                                                                                                                                                                                                of
                                                                                                                                                                                                his
                                                                                                                                                                                                or
                                                                                                                                                                                                her
                                                                                                                                                                                                right
                                                                                                                                                                                                to
                                                                                                                                                                                                free
                                                                                                                                                                                                speech,
                                                                                                                                                                                                our
                                                                                                                                                                                                compliance
                                                                                                                                                                                                requirements
                                                                                                                                                                                                resulting
                                                                                                                                                                                                from
                                                                                                                                                                                                a
                                                                                                                                                                                                legal
                                                                                                                                                                                                obligation,
                                                                                                                                                                                                or
                                                                                                                                                                                                any
                                                                                                                                                                                                processing
                                                                                                                                                                                                that
                                                                                                                                                                                                may
                                                                                                                                                                                                be
                                                                                                                                                                                                required
                                                                                                                                                                                                to
                                                                                                                                                                                                protect
                                                                                                                                                                                                against
                                                                                                                                                                                                illegal
                                                                                                                                                                                                activities.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <u>
                                                                                                                                                                                                    Right
                                                                                                                                                                                                    to
                                                                                                                                                                                                    be
                                                                                                                                                                                                    informed
                                                                                                                                                                                                    —
                                                                                                                                                                                                    Request
                                                                                                                                                                                                    to
                                                                                                                                                                                                    know
                                                                                                                                                                                                </u>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                Depending
                                                                                                                                                                                                on
                                                                                                                                                                                                the
                                                                                                                                                                                                circumstances,
                                                                                                                                                                                                you
                                                                                                                                                                                                have
                                                                                                                                                                                                a
                                                                                                                                                                                                right
                                                                                                                                                                                                to
                                                                                                                                                                                                know:
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    whether
                                                                                                                                                                                                    we
                                                                                                                                                                                                    collect
                                                                                                                                                                                                    and
                                                                                                                                                                                                    use
                                                                                                                                                                                                    your
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information;
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    the
                                                                                                                                                                                                    categories
                                                                                                                                                                                                    of
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information
                                                                                                                                                                                                    that
                                                                                                                                                                                                    we
                                                                                                                                                                                                    collect;
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    the
                                                                                                                                                                                                    purposes
                                                                                                                                                                                                    for
                                                                                                                                                                                                    which
                                                                                                                                                                                                    the
                                                                                                                                                                                                    collected
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information
                                                                                                                                                                                                    is
                                                                                                                                                                                                    used;
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    whether
                                                                                                                                                                                                    we
                                                                                                                                                                                                    sell
                                                                                                                                                                                                    or
                                                                                                                                                                                                    share
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information
                                                                                                                                                                                                    to
                                                                                                                                                                                                    third
                                                                                                                                                                                                    parties;
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    the
                                                                                                                                                                                                    categories
                                                                                                                                                                                                    of
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information
                                                                                                                                                                                                    that
                                                                                                                                                                                                    we
                                                                                                                                                                                                    sold,
                                                                                                                                                                                                    shared,
                                                                                                                                                                                                    or
                                                                                                                                                                                                    disclosed
                                                                                                                                                                                                    for
                                                                                                                                                                                                    a
                                                                                                                                                                                                    business
                                                                                                                                                                                                    purpose;
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    the
                                                                                                                                                                                                    categories
                                                                                                                                                                                                    of
                                                                                                                                                                                                    third
                                                                                                                                                                                                    parties
                                                                                                                                                                                                    to
                                                                                                                                                                                                    whom
                                                                                                                                                                                                    the
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information
                                                                                                                                                                                                    was
                                                                                                                                                                                                    sold,
                                                                                                                                                                                                    shared,
                                                                                                                                                                                                    or
                                                                                                                                                                                                    disclosed
                                                                                                                                                                                                    for
                                                                                                                                                                                                    a
                                                                                                                                                                                                    business
                                                                                                                                                                                                    purpose;
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    the
                                                                                                                                                                                                    business
                                                                                                                                                                                                    or
                                                                                                                                                                                                    commercial
                                                                                                                                                                                                    purpose
                                                                                                                                                                                                    for
                                                                                                                                                                                                    collecting,
                                                                                                                                                                                                    selling,
                                                                                                                                                                                                    or
                                                                                                                                                                                                    sharing
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information;
                                                                                                                                                                                                    and
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <bdt className="block-component">
                                                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            the
                                                                                                                                                                                            specific
                                                                                                                                                                                            pieces
                                                                                                                                                                                            of
                                                                                                                                                                                            personal
                                                                                                                                                                                            information
                                                                                                                                                                                            we
                                                                                                                                                                                            collected
                                                                                                                                                                                            about
                                                                                                                                                                                            you.
                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                In
                                                                                                                                                                                                accordance
                                                                                                                                                                                                with
                                                                                                                                                                                                applicable
                                                                                                                                                                                                law,
                                                                                                                                                                                                we
                                                                                                                                                                                                are
                                                                                                                                                                                                not
                                                                                                                                                                                                obligated
                                                                                                                                                                                                to
                                                                                                                                                                                                provide
                                                                                                                                                                                                or
                                                                                                                                                                                                delete
                                                                                                                                                                                                consumer
                                                                                                                                                                                                information
                                                                                                                                                                                                that
                                                                                                                                                                                                is
                                                                                                                                                                                                de-identified
                                                                                                                                                                                                in
                                                                                                                                                                                                response
                                                                                                                                                                                                to
                                                                                                                                                                                                a
                                                                                                                                                                                                consumer
                                                                                                                                                                                                request
                                                                                                                                                                                                or
                                                                                                                                                                                                to
                                                                                                                                                                                                re-identify
                                                                                                                                                                                                individual
                                                                                                                                                                                                data
                                                                                                                                                                                                to
                                                                                                                                                                                                verify
                                                                                                                                                                                                a
                                                                                                                                                                                                consumer
                                                                                                                                                                                                request.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <u>
                                                                                                                                                                                                    Right
                                                                                                                                                                                                    to
                                                                                                                                                                                                    Non-Discrimination
                                                                                                                                                                                                    for
                                                                                                                                                                                                    the
                                                                                                                                                                                                    Exercise
                                                                                                                                                                                                    of
                                                                                                                                                                                                    a
                                                                                                                                                                                                    Consumer’s
                                                                                                                                                                                                    Privacy
                                                                                                                                                                                                    Rights
                                                                                                                                                                                                </u>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                We
                                                                                                                                                                                                will
                                                                                                                                                                                                not
                                                                                                                                                                                                discriminate
                                                                                                                                                                                                against
                                                                                                                                                                                                you
                                                                                                                                                                                                if
                                                                                                                                                                                                you
                                                                                                                                                                                                exercise
                                                                                                                                                                                                your
                                                                                                                                                                                                privacy
                                                                                                                                                                                                rights.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <u>
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            Right
                                                                                                                                                                                            to
                                                                                                                                                                                            Limit
                                                                                                                                                                                            Use
                                                                                                                                                                                            and
                                                                                                                                                                                            Disclosure
                                                                                                                                                                                            of
                                                                                                                                                                                            Sensitive
                                                                                                                                                                                            Personal
                                                                                                                                                                                            Information
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </u>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <bdt className="block-component">
                                                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        We
                                                                                                                                                                                        do
                                                                                                                                                                                        not
                                                                                                                                                                                        process
                                                                                                                                                                                        consumer's
                                                                                                                                                                                        sensitive
                                                                                                                                                                                        personal
                                                                                                                                                                                        information.
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                        <span data-custom-class="body_text" />
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <u>
                                                                                                                                                                                                    Verification
                                                                                                                                                                                                    process
                                                                                                                                                                                                </u>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                Upon
                                                                                                                                                                                                receiving
                                                                                                                                                                                                your
                                                                                                                                                                                                request,
                                                                                                                                                                                                we
                                                                                                                                                                                                will
                                                                                                                                                                                                need
                                                                                                                                                                                                to
                                                                                                                                                                                                verify
                                                                                                                                                                                                your
                                                                                                                                                                                                identity
                                                                                                                                                                                                to
                                                                                                                                                                                                determine
                                                                                                                                                                                                you
                                                                                                                                                                                                are
                                                                                                                                                                                                the
                                                                                                                                                                                                same
                                                                                                                                                                                                person
                                                                                                                                                                                                about
                                                                                                                                                                                                whom
                                                                                                                                                                                                we
                                                                                                                                                                                                have
                                                                                                                                                                                                the
                                                                                                                                                                                                information
                                                                                                                                                                                                in
                                                                                                                                                                                                our
                                                                                                                                                                                                system.
                                                                                                                                                                                                These
                                                                                                                                                                                                verification
                                                                                                                                                                                                efforts
                                                                                                                                                                                                require
                                                                                                                                                                                                us
                                                                                                                                                                                                to
                                                                                                                                                                                                ask
                                                                                                                                                                                                you
                                                                                                                                                                                                to
                                                                                                                                                                                                provide
                                                                                                                                                                                                information
                                                                                                                                                                                                so
                                                                                                                                                                                                that
                                                                                                                                                                                                we
                                                                                                                                                                                                can
                                                                                                                                                                                                match
                                                                                                                                                                                                it
                                                                                                                                                                                                with
                                                                                                                                                                                                information
                                                                                                                                                                                                you
                                                                                                                                                                                                have
                                                                                                                                                                                                previously
                                                                                                                                                                                                provided
                                                                                                                                                                                                us.
                                                                                                                                                                                                For
                                                                                                                                                                                                instance,
                                                                                                                                                                                                depending
                                                                                                                                                                                                on
                                                                                                                                                                                                the
                                                                                                                                                                                                type
                                                                                                                                                                                                of
                                                                                                                                                                                                request
                                                                                                                                                                                                you
                                                                                                                                                                                                submit,
                                                                                                                                                                                                we
                                                                                                                                                                                                may
                                                                                                                                                                                                ask
                                                                                                                                                                                                you
                                                                                                                                                                                                to
                                                                                                                                                                                                provide
                                                                                                                                                                                                certain
                                                                                                                                                                                                information
                                                                                                                                                                                                so
                                                                                                                                                                                                that
                                                                                                                                                                                                we
                                                                                                                                                                                                can
                                                                                                                                                                                                match
                                                                                                                                                                                                the
                                                                                                                                                                                                information
                                                                                                                                                                                                you
                                                                                                                                                                                                provide
                                                                                                                                                                                                with
                                                                                                                                                                                                the
                                                                                                                                                                                                information
                                                                                                                                                                                                we
                                                                                                                                                                                                already
                                                                                                                                                                                                have
                                                                                                                                                                                                on
                                                                                                                                                                                                file,
                                                                                                                                                                                                or
                                                                                                                                                                                                we
                                                                                                                                                                                                may
                                                                                                                                                                                                contact
                                                                                                                                                                                                you
                                                                                                                                                                                                through
                                                                                                                                                                                                a
                                                                                                                                                                                                communication
                                                                                                                                                                                                method
                                                                                                                                                                                                (e.g.
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                ,
                                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                phone
                                                                                                                                                                                                or
                                                                                                                                                                                                email)
                                                                                                                                                                                                that
                                                                                                                                                                                                you
                                                                                                                                                                                                have
                                                                                                                                                                                                previously
                                                                                                                                                                                                provided
                                                                                                                                                                                                to
                                                                                                                                                                                                us.
                                                                                                                                                                                                We
                                                                                                                                                                                                may
                                                                                                                                                                                                also
                                                                                                                                                                                                use
                                                                                                                                                                                                other
                                                                                                                                                                                                verification
                                                                                                                                                                                                methods
                                                                                                                                                                                                as
                                                                                                                                                                                                the
                                                                                                                                                                                                circumstances
                                                                                                                                                                                                dictate.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                We
                                                                                                                                                                                                will
                                                                                                                                                                                                only
                                                                                                                                                                                                use
                                                                                                                                                                                                personal
                                                                                                                                                                                                information
                                                                                                                                                                                                provided
                                                                                                                                                                                                in
                                                                                                                                                                                                your
                                                                                                                                                                                                request
                                                                                                                                                                                                to
                                                                                                                                                                                                verify
                                                                                                                                                                                                your
                                                                                                                                                                                                identity
                                                                                                                                                                                                or
                                                                                                                                                                                                authority
                                                                                                                                                                                                to
                                                                                                                                                                                                make
                                                                                                                                                                                                the
                                                                                                                                                                                                request.
                                                                                                                                                                                                To
                                                                                                                                                                                                the
                                                                                                                                                                                                extent
                                                                                                                                                                                                possible,
                                                                                                                                                                                                we
                                                                                                                                                                                                will
                                                                                                                                                                                                avoid
                                                                                                                                                                                                requesting
                                                                                                                                                                                                additional
                                                                                                                                                                                                information
                                                                                                                                                                                                from
                                                                                                                                                                                                you
                                                                                                                                                                                                for
                                                                                                                                                                                                the
                                                                                                                                                                                                purposes
                                                                                                                                                                                                of
                                                                                                                                                                                                verification.
                                                                                                                                                                                                However,
                                                                                                                                                                                                if
                                                                                                                                                                                                we
                                                                                                                                                                                                cannot
                                                                                                                                                                                                verify
                                                                                                                                                                                                your
                                                                                                                                                                                                identity
                                                                                                                                                                                                from
                                                                                                                                                                                                the
                                                                                                                                                                                                information
                                                                                                                                                                                                already
                                                                                                                                                                                                maintained
                                                                                                                                                                                                by
                                                                                                                                                                                                us,
                                                                                                                                                                                                we
                                                                                                                                                                                                may
                                                                                                                                                                                                request
                                                                                                                                                                                                that
                                                                                                                                                                                                you
                                                                                                                                                                                                provide
                                                                                                                                                                                                additional
                                                                                                                                                                                                information
                                                                                                                                                                                                for
                                                                                                                                                                                                the
                                                                                                                                                                                                purposes
                                                                                                                                                                                                of
                                                                                                                                                                                                verifying
                                                                                                                                                                                                your
                                                                                                                                                                                                identity
                                                                                                                                                                                                and
                                                                                                                                                                                                for
                                                                                                                                                                                                security
                                                                                                                                                                                                or
                                                                                                                                                                                                fraud-prevention
                                                                                                                                                                                                purposes.
                                                                                                                                                                                                We
                                                                                                                                                                                                will
                                                                                                                                                                                                delete
                                                                                                                                                                                                such
                                                                                                                                                                                                additionally
                                                                                                                                                                                                provided
                                                                                                                                                                                                information
                                                                                                                                                                                                as
                                                                                                                                                                                                soon
                                                                                                                                                                                                as
                                                                                                                                                                                                we
                                                                                                                                                                                                finish
                                                                                                                                                                                                verifying
                                                                                                                                                                                                you.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <u>
                                                                                                                                                                                                    Other
                                                                                                                                                                                                    privacy
                                                                                                                                                                                                    rights
                                                                                                                                                                                                </u>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    You
                                                                                                                                                                                                    may
                                                                                                                                                                                                    object
                                                                                                                                                                                                    to
                                                                                                                                                                                                    the
                                                                                                                                                                                                    processing
                                                                                                                                                                                                    of
                                                                                                                                                                                                    your
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information.
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <span
                                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                                        "rgb(0, 0, 0)"
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                            >
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    You
                                                                                                                                                                                                    may
                                                                                                                                                                                                    request
                                                                                                                                                                                                    correction
                                                                                                                                                                                                    of
                                                                                                                                                                                                    your
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    data
                                                                                                                                                                                                    if
                                                                                                                                                                                                    it
                                                                                                                                                                                                    is
                                                                                                                                                                                                    incorrect
                                                                                                                                                                                                    or
                                                                                                                                                                                                    no
                                                                                                                                                                                                    longer
                                                                                                                                                                                                    relevant,
                                                                                                                                                                                                    or
                                                                                                                                                                                                    ask
                                                                                                                                                                                                    to
                                                                                                                                                                                                    restrict
                                                                                                                                                                                                    the
                                                                                                                                                                                                    processing
                                                                                                                                                                                                    of
                                                                                                                                                                                                    the
                                                                                                                                                                                                    information.
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <span
                                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                                        "rgb(0, 0, 0)"
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                            >
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    You
                                                                                                                                                                                                    can
                                                                                                                                                                                                    designate
                                                                                                                                                                                                    an{" "}
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                    authorized
                                                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                    agent
                                                                                                                                                                                                    to
                                                                                                                                                                                                    make
                                                                                                                                                                                                    a
                                                                                                                                                                                                    request
                                                                                                                                                                                                    under
                                                                                                                                                                                                    the
                                                                                                                                                                                                    CCPA
                                                                                                                                                                                                    on
                                                                                                                                                                                                    your
                                                                                                                                                                                                    behalf.
                                                                                                                                                                                                    We
                                                                                                                                                                                                    may
                                                                                                                                                                                                    deny
                                                                                                                                                                                                    a
                                                                                                                                                                                                    request
                                                                                                                                                                                                    from
                                                                                                                                                                                                    an{" "}
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                    authorized
                                                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                    agent
                                                                                                                                                                                                    that
                                                                                                                                                                                                    does
                                                                                                                                                                                                    not
                                                                                                                                                                                                    submit
                                                                                                                                                                                                    proof
                                                                                                                                                                                                    that
                                                                                                                                                                                                    they
                                                                                                                                                                                                    have
                                                                                                                                                                                                    been
                                                                                                                                                                                                    validly{" "}
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                    authorized
                                                                                                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                    to
                                                                                                                                                                                                    act
                                                                                                                                                                                                    on
                                                                                                                                                                                                    your
                                                                                                                                                                                                    behalf
                                                                                                                                                                                                    in
                                                                                                                                                                                                    accordance
                                                                                                                                                                                                    with
                                                                                                                                                                                                    the
                                                                                                                                                                                                    CCPA.
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <span
                                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                                        "rgb(0, 0, 0)"
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                            >
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                color:
                                                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <ul>
                                                                                                                                                                                    <li
                                                                                                                                                                                        style={{
                                                                                                                                                                                            lineHeight:
                                                                                                                                                                                                "1.5"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    You
                                                                                                                                                                                                    may
                                                                                                                                                                                                    request
                                                                                                                                                                                                    to
                                                                                                                                                                                                    opt
                                                                                                                                                                                                    out
                                                                                                                                                                                                    from
                                                                                                                                                                                                    future
                                                                                                                                                                                                    selling
                                                                                                                                                                                                    or
                                                                                                                                                                                                    sharing
                                                                                                                                                                                                    of
                                                                                                                                                                                                    your
                                                                                                                                                                                                    personal
                                                                                                                                                                                                    information
                                                                                                                                                                                                    to
                                                                                                                                                                                                    third
                                                                                                                                                                                                    parties.
                                                                                                                                                                                                    Upon
                                                                                                                                                                                                    receiving
                                                                                                                                                                                                    an
                                                                                                                                                                                                    opt-out
                                                                                                                                                                                                    request,
                                                                                                                                                                                                    we
                                                                                                                                                                                                    will
                                                                                                                                                                                                    act
                                                                                                                                                                                                    upon
                                                                                                                                                                                                    the
                                                                                                                                                                                                    request
                                                                                                                                                                                                    as
                                                                                                                                                                                                    soon
                                                                                                                                                                                                    as
                                                                                                                                                                                                    feasibly
                                                                                                                                                                                                    possible,
                                                                                                                                                                                                    but
                                                                                                                                                                                                    no
                                                                                                                                                                                                    later
                                                                                                                                                                                                    than
                                                                                                                                                                                                    fifteen
                                                                                                                                                                                                    (15)
                                                                                                                                                                                                    days
                                                                                                                                                                                                    from
                                                                                                                                                                                                    the
                                                                                                                                                                                                    date
                                                                                                                                                                                                    of
                                                                                                                                                                                                    the
                                                                                                                                                                                                    request
                                                                                                                                                                                                    submission.
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <span
                                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                                        "rgb(0, 0, 0)"
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                            >
                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </li>
                                                                                                                                                                                </ul>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                To
                                                                                                                                                                                                exercise
                                                                                                                                                                                                these
                                                                                                                                                                                                rights,
                                                                                                                                                                                                you
                                                                                                                                                                                                can
                                                                                                                                                                                                contact
                                                                                                                                                                                                us&nbsp;
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                                                                                            </bdt>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        or
                                                                                                                                                                                        by
                                                                                                                                                                                        referring
                                                                                                                                                                                        to
                                                                                                                                                                                        the
                                                                                                                                                                                        contact
                                                                                                                                                                                        details
                                                                                                                                                                                        at
                                                                                                                                                                                        the
                                                                                                                                                                                        bottom
                                                                                                                                                                                        of
                                                                                                                                                                                        this
                                                                                                                                                                                        document.
                                                                                                                                                                                        If
                                                                                                                                                                                        you
                                                                                                                                                                                        have
                                                                                                                                                                                        a
                                                                                                                                                                                        complaint
                                                                                                                                                                                        about
                                                                                                                                                                                        how
                                                                                                                                                                                        we
                                                                                                                                                                                        handle
                                                                                                                                                                                        your
                                                                                                                                                                                        data,
                                                                                                                                                                                        we
                                                                                                                                                                                        would
                                                                                                                                                                                        like
                                                                                                                                                                                        to
                                                                                                                                                                                        hear
                                                                                                                                                                                        from
                                                                                                                                                                                        you.
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                                                <bdt className="block-component">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            color:
                                                                                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                            <span
                                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                                        " rgb(0, 0, 0))",
                                                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                            >
                                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                            color:
                                                                                                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                            </bdt>
                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </bdt>
                                                                                                                                                                                            </bdt>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <bdt className="block-component">
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    id="policyupdates"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0)"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        id="control"
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <strong>
                                                                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                                                                                11.
                                                                                                                                                                                                                DO
                                                                                                                                                                                                                WE
                                                                                                                                                                                                                MAKE
                                                                                                                                                                                                                UPDATES
                                                                                                                                                                                                                TO
                                                                                                                                                                                                                THIS
                                                                                                                                                                                                                NOTICE?
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </strong>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <em>
                                                                                                                                                                                                    <strong>
                                                                                                                                                                                                        In
                                                                                                                                                                                                        Short:&nbsp;
                                                                                                                                                                                                    </strong>
                                                                                                                                                                                                    Yes,
                                                                                                                                                                                                    we
                                                                                                                                                                                                    will
                                                                                                                                                                                                    update
                                                                                                                                                                                                    this
                                                                                                                                                                                                    notice
                                                                                                                                                                                                    as
                                                                                                                                                                                                    necessary
                                                                                                                                                                                                    to
                                                                                                                                                                                                    stay
                                                                                                                                                                                                    compliant
                                                                                                                                                                                                    with
                                                                                                                                                                                                    relevant
                                                                                                                                                                                                    laws.
                                                                                                                                                                                                </em>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                We
                                                                                                                                                                                                may
                                                                                                                                                                                                update
                                                                                                                                                                                                this
                                                                                                                                                                                                privacy
                                                                                                                                                                                                notice
                                                                                                                                                                                                from
                                                                                                                                                                                                time
                                                                                                                                                                                                to
                                                                                                                                                                                                time.
                                                                                                                                                                                                The
                                                                                                                                                                                                updated
                                                                                                                                                                                                version
                                                                                                                                                                                                will
                                                                                                                                                                                                be
                                                                                                                                                                                                indicated
                                                                                                                                                                                                by
                                                                                                                                                                                                an
                                                                                                                                                                                                updated{" "}
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                "Revised"
                                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                                                                                                date
                                                                                                                                                                                                and
                                                                                                                                                                                                the
                                                                                                                                                                                                updated
                                                                                                                                                                                                version
                                                                                                                                                                                                will
                                                                                                                                                                                                be
                                                                                                                                                                                                effective
                                                                                                                                                                                                as
                                                                                                                                                                                                soon
                                                                                                                                                                                                as
                                                                                                                                                                                                it
                                                                                                                                                                                                is
                                                                                                                                                                                                accessible.
                                                                                                                                                                                                If
                                                                                                                                                                                                we
                                                                                                                                                                                                make
                                                                                                                                                                                                material
                                                                                                                                                                                                changes
                                                                                                                                                                                                to
                                                                                                                                                                                                this
                                                                                                                                                                                                privacy
                                                                                                                                                                                                notice,
                                                                                                                                                                                                we
                                                                                                                                                                                                may
                                                                                                                                                                                                notify
                                                                                                                                                                                                you
                                                                                                                                                                                                either
                                                                                                                                                                                                by
                                                                                                                                                                                                prominently
                                                                                                                                                                                                posting
                                                                                                                                                                                                a
                                                                                                                                                                                                notice
                                                                                                                                                                                                of
                                                                                                                                                                                                such
                                                                                                                                                                                                changes
                                                                                                                                                                                                or
                                                                                                                                                                                                by
                                                                                                                                                                                                directly
                                                                                                                                                                                                sending
                                                                                                                                                                                                you
                                                                                                                                                                                                a
                                                                                                                                                                                                notification.
                                                                                                                                                                                                We
                                                                                                                                                                                                encourage
                                                                                                                                                                                                you
                                                                                                                                                                                                to
                                                                                                                                                                                                review
                                                                                                                                                                                                this
                                                                                                                                                                                                privacy
                                                                                                                                                                                                notice
                                                                                                                                                                                                frequently
                                                                                                                                                                                                to
                                                                                                                                                                                                be
                                                                                                                                                                                                informed
                                                                                                                                                                                                of
                                                                                                                                                                                                how
                                                                                                                                                                                                we
                                                                                                                                                                                                are
                                                                                                                                                                                                protecting
                                                                                                                                                                                                your
                                                                                                                                                                                                information.
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    id="contact"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0)"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        id="control"
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <strong>
                                                                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                                                                                12.
                                                                                                                                                                                                                HOW
                                                                                                                                                                                                                CAN
                                                                                                                                                                                                                YOU
                                                                                                                                                                                                                CONTACT
                                                                                                                                                                                                                US
                                                                                                                                                                                                                ABOUT
                                                                                                                                                                                                                THIS
                                                                                                                                                                                                                NOTICE?
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </strong>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                If
                                                                                                                                                                                                you
                                                                                                                                                                                                have
                                                                                                                                                                                                questions
                                                                                                                                                                                                or
                                                                                                                                                                                                comments
                                                                                                                                                                                                about
                                                                                                                                                                                                this
                                                                                                                                                                                                notice,
                                                                                                                                                                                                you
                                                                                                                                                                                                may{" "}
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))",
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                        email
                                                                                                                                                                                                        us
                                                                                                                                                                                                        at{" "}
                                                                                                                                                                                                        <bdt className="question">
                                                                                                                                                                                                            __________
                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                                            &nbsp;or
                                                                                                                                                                                                            by
                                                                                                                                                                                                            post
                                                                                                                                                                                                            to:
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                color:
                                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <bdt className="question">
                                                                                                                                                                                                                    My
                                                                                                                                                                                                                    Instants
                                                                                                                                                                                                                </bdt>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <bdt className="question">
                                                                                                                                                                                                __________
                                                                                                                                                                                            </bdt>
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <bdt className="question">
                                                                                                                                                                                                __________
                                                                                                                                                                                            </bdt>
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <bdt className="block-component" />
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <bdt className="question">
                                                                                                                                                                                                Pakistan
                                                                                                                                                                                            </bdt>
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            color:
                                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                                            <span
                                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                                                }}
                                                                                                                                                                                                                            >
                                                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                            color:
                                                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                </span>
                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        color:
                                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                    style={{
                                                                                                                                                                                                                        fontSize: 15
                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                >
                                                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                                                        <bdt className="block-component">
                                                                                                                                                                                                                            <bdt className="block-component" />
                                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                </span>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                " rgb(0, 0, 0))",
                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <span
                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                                                                                </bdt>
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </span>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    id="request"
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0)"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))",
                                                                                                                                                                                                fontSize: 15
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    fontSize: 15,
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        " rgb(0, 0, 0))"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span
                                                                                                                                                                                                    style={{
                                                                                                                                                                                                        fontSize: 15,
                                                                                                                                                                                                        color:
                                                                                                                                                                                                            " rgb(0, 0, 0))"
                                                                                                                                                                                                    }}
                                                                                                                                                                                                >
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        id="control"
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            color:
                                                                                                                                                                                                                "rgb(0, 0, 0)"
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <strong>
                                                                                                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                                                                                                13.
                                                                                                                                                                                                                HOW
                                                                                                                                                                                                                CAN
                                                                                                                                                                                                                YOU
                                                                                                                                                                                                                REVIEW,
                                                                                                                                                                                                                UPDATE,
                                                                                                                                                                                                                OR
                                                                                                                                                                                                                DELETE
                                                                                                                                                                                                                THE
                                                                                                                                                                                                                DATA
                                                                                                                                                                                                                WE
                                                                                                                                                                                                                COLLECT
                                                                                                                                                                                                                FROM
                                                                                                                                                                                                                YOU?
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </strong>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <br />
                                                                                                                                                                                </div>
                                                                                                                                                                                <div
                                                                                                                                                                                    style={{
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "1.5"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15,
                                                                                                                                                                                            color:
                                                                                                                                                                                                " rgb(0, 0, 0))"
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                fontSize: 15,
                                                                                                                                                                                                color:
                                                                                                                                                                                                    " rgb(0, 0, 0))"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                Based
                                                                                                                                                                                                on
                                                                                                                                                                                                the
                                                                                                                                                                                                applicable
                                                                                                                                                                                                laws
                                                                                                                                                                                                of
                                                                                                                                                                                                your
                                                                                                                                                                                                country,
                                                                                                                                                                                                you
                                                                                                                                                                                                may
                                                                                                                                                                                                have
                                                                                                                                                                                                the
                                                                                                                                                                                                right
                                                                                                                                                                                                to
                                                                                                                                                                                                request
                                                                                                                                                                                                access
                                                                                                                                                                                                to
                                                                                                                                                                                                the
                                                                                                                                                                                                personal
                                                                                                                                                                                                information
                                                                                                                                                                                                we
                                                                                                                                                                                                collect
                                                                                                                                                                                                from
                                                                                                                                                                                                you,
                                                                                                                                                                                                change
                                                                                                                                                                                                that
                                                                                                                                                                                                information,
                                                                                                                                                                                                or
                                                                                                                                                                                                delete
                                                                                                                                                                                                it.{" "}
                                                                                                                                                                                                <bdt className="else-block" />
                                                                                                                                                                                                To
                                                                                                                                                                                                request
                                                                                                                                                                                                to
                                                                                                                                                                                                review,
                                                                                                                                                                                                update,
                                                                                                                                                                                                or
                                                                                                                                                                                                delete
                                                                                                                                                                                                your
                                                                                                                                                                                                personal
                                                                                                                                                                                                information,
                                                                                                                                                                                                please{" "}
                                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                                fill
                                                                                                                                                                                                out
                                                                                                                                                                                                and
                                                                                                                                                                                                submit
                                                                                                                                                                                                a&nbsp;
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        "rgb(48, 48, 241)"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                                                                    <span
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                                        }}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        <a
                                                                                                                                                                                                            data-custom-class="link"
                                                                                                                                                                                                            href="https://app.termly.io/notify/3be090b0-aa16-43f2-aa38-8b31785b0b57"
                                                                                                                                                                                                            rel="noopener noreferrer"
                                                                                                                                                                                                            target="_blank"
                                                                                                                                                                                                        >
                                                                                                                                                                                                            data
                                                                                                                                                                                                            subject
                                                                                                                                                                                                            access
                                                                                                                                                                                                            request
                                                                                                                                                                                                        </a>
                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </span>
                                                                                                                                                                                            </span>
                                                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                                                                            </bdt>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                                                        .
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <style
                                                                                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                                                                                        __html:
                                                                                                                                                                                            "\n        ul {\n          list-style-type: square;\n        }\n        ul > li > ul {\n          list-style-type: circle;\n        }\n        ul > li > ul > li > ul {\n          list-style-type: square;\n        }\n        ol li {\n          font-family: Arial ;\n        }\n      "
                                                                                                                                                                                    }}
                                                                                                                                                                                />
                                                                                                                                                                            </div>
                                                                                                                                                                           
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
        </div>


    )
}

export default PrivacyPolicy;