import React from 'react';

function ContactUs() {
  return (
    <div>
      <h1>Contact Us</h1>

      <p>Thank you for visiting MyInstants.org! If you have any questions, comments, or feedback, please feel free to reach out to us. We value your input and are here to assist you in any way we can.</p>

      <h2>Contact Information</h2>
      <p>You can reach us using the following contact details:</p>

      <ul>
      <li>Name: Ayush</li>
        <li>Email: info@myinstants.org</li>
        <li>Phone:  +91 9876243993</li>
        <li>Address: Industrial Area Phase - I , Chandigarh , India</li>
      </ul>

      <h2>Contact Form</h2>
      <p>Alternatively, you can fill out the form below to send us a message:</p>

      <form action="submit-form.php" method="POST">
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />
        </div>

        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />
        </div>

        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="5" required></textarea>
        </div>

        <button type="submit">Send Message</button>
      </form>

      <p>We will make every effort to respond to your message as soon as possible. Thank you for getting in touch with us!</p>
    </div>
  );
}

export default ContactUs;
