import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import CategoryIcon from '@mui/icons-material/Category';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';


function Foooter() {
  return (<Footer backgroundColor="#1976d2" sx={{ width: "320" }}
    columns={[
      {
        icon: (
          <CategoryIcon />
        ),
        title: 'Categories',
        items: [{
          title: 'All Soundboards',
          url: '/All_SoundBoard',
        }, {
          title: "Meme Soundboard",
          url: "/Meme_SoundBoard"

        }, {
          title: "Game Soundboard",
          url: "/games_sounds"

        }, {
          title: "Animal Soundboard",
          url: "/animals"

        }, {
          title: "Sleep Sounds",
          url: "/Sleep_Soundboard"

        }, {
          title: "Funny Soundboard",
          url: "/Funny_SoundBoard"

        }, {
          title: "Notification Sounds",
          url: "/Notifications_SoundBoard"

        }, {
          title: "Ringtunes",
          url: "/RingTunes_Soundboard"

        }]
      }, {
        icon: (
          <CategoryIcon />
        ),
        title: 'Categories 2',
        items: [{
          title: "Bird SoundBoard",
          url: "/Bird_SoundBoard"

        }, {
          title: "Whoosh Soundboard",
          url: "/whoosh_soundboard"

        }, {
          title: "Laugh Soundboard",
          url: "/Laugh_Soundboard"

        }, {
          title: "Rain SoundBoard",
          url: "/Rain_SoundBoard"

        }, {
          title: "Horror SoundBoard",
          url: "/Horror_SoundBoard"

        }, {
          title: "Earth Sounds",
          url: "/Nature_Sounds"

        }, {
          title: "Nature Sounds",
          url: "/Nature_SoundBoard"

        }, {
          title: "Gun SoundBoard",
          url: "/Gun_SoundBoard"

        }]
      }, {
        icon: (
          <CategoryIcon />
        ),
        title: 'Categories 3',
        items: [{
          title: "Anime Sounds",
          url: "/Anime_Sounds"

        }, {
          title: "Annoying Sounds",
          url: "/Annoying_SoundBoard"

        }, {
          title: "Car Sounds",
          url: "/Car_SoundBoard"

        }, {
          title: "Movie Sounds",
          url: "/Movie_SoundBoard"

        }, {
          title: "Music Soundboard",
          url: "/Music_SoundBoard"

        }, {
          title: "Politics Soundboards",
          url: "/Politcs_SoundBoard"

        }, {
          title: "Prank Soundboards",
          url: "/Prank_SoundBoard"

        }, {
          title: "Reaction Soundboards",
          url: "/Reaction_SoundBoard"

        }]
      },

      {
        icon: (
          <DirectionsBikeIcon />
        ),
        title: 'Others',
        items: [{
          title: "Fart Soundboard",
          url: "/search/fart"
        }, {
          title: "Sound Effects",
          url: "/SoundEffect_SoundBoard"
        }, {
          title: "Sport Soundboard",
          url: "/Sport_SoundBoard"
        }, {
          title: "Television  Soundboard",
          url: "/Television_SoundBoard"
        }, {
          title: "TikTok Soundboard",
          url: "/Tiktok_SoundBoard"
        }, {
          title: "Viral  Soundboard",
          url: "/Viral_SoundBoard"
        }, {
          title: "WhatsApp Soundboard",
          url: "/Whatsapp_SoundBoard"
        }]

      }, {
        icon: (

          <PrivacyTipIcon />
        ),
        title: 'Privacy and Terms',
        items: [{
          title: "Privacy Policy",
          url: "/PrivacyPolicy"
        }, {
          title: "Terms and Conditions",
          url: "/TermsAndConditions"
        },{
          title: "AboutUs",
          url: "/about-us"
        },{
          title: "Disclaimer",
          url: "/disclaimer"
        },{
          title: "Contact Us",
          url: "/contact-us"
        },{
          title: "Blog",
          url: "/blog/"
        },
        {
          title: "Meme Generator",
          url: "/memes/online-meme-generator/"
        }
      ]
      },
    ]}
    bottom="Copyright © 2024 Myinstants.org. All rights reserved."
  />
  )
}

export default Foooter;