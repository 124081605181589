import React, { useState } from "react";
// import MainComponent from "./Components/MainComponent";
import AppHeader from "./Components/AppHeader";
// import SoundInfoPage from "./Components/SoundInfoPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import ScrollToTop from "./Components/ScrollToTop";
import { Suspense } from "react";
import Foooter from "./Components/Foooter";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsAndConditions from "./Components/TermsAndConditions";
import AboutUs from "./Components/AboutUs";
import Disclaimer from "./Components/Disclaimer";
import ContactUs from "./Components/ContactUs";
// import { useDetectAdBlock } from "adblock-detect-react";
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
// import { useEffect} from "react";

const MainComponent = React.lazy(() => import("./Components/MainComponent"));
const SoundInfoPage = React.lazy(() => import("./Components/SoundInfoPage"));

const P404 = React.lazy(() => import("./Components/P404"));


function App() {

  const [activeSounds, setActiveSounds] = useState([0, 20]);

  // const AdBlockDetector = () => {
  //   const adBlockDetected = useDetectAdBlock();
  //   //const adBlockDetected = true;
  //   const [open, setOpen] = useState(false);
  //   const [delayDone, setDelayDone] = useState(false);
  
  //   const handleOpen = () => {
  //     setOpen(true);
  //   };
  
  //   useEffect(() => {
  //     const hasDelayDone = sessionStorage.getItem('delayDone');
  //     if (hasDelayDone) {
  //       setDelayDone(true);
  //     }
  //   }, []);
  
  //   useEffect(() => {
  //     if (!delayDone) {
  //       // Creating a timeout within the useEffect hook
  //       const timeout = setTimeout(() => {
  //         handleOpen(); // Open the dialog after the timeout
  //         setDelayDone(true); // Set the delayDone to true to indicate that the delay has been done
  //         sessionStorage.setItem('delayDone', 'true'); // Set a flag to indicate that the delay has been done
  //       }, 20000);
  //       // Clear the timeout if the component is unmounted before the delay
  //       //return () => clearTimeout(timeout);
  //     }
  //     else{
  //       handleOpen();
  //     }
  //   }, [delayDone]);
  
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  
  //   if (adBlockDetected) {
  //     return (
  //       <Dialog
  //         open={open}
  //         // TransitionComponent={Transition}
  //         keepMounted
  //         onClose={handleClose}
  //         aria-describedby="alert-dialog-slide-description"
  //       >
  //         <DialogTitle>{"Please Disable AdBlock"}</DialogTitle>
  //         <DialogContent>
  //           <DialogContentText id="alert-dialog-slide-description">
  //             We at MyInstants.org work really hard to provide you with free sounds. Please support us by disabling ad blocker.
  //           </DialogContentText>
  //         </DialogContent>
  //         <DialogActions>
  //           <Button onClick={() => window.location.reload()}>Refresh</Button>
  //         </DialogActions>
  //       </Dialog>
  //     );
  //   } else {
  //     return null;
  //   }
  // }

  return (
    <div className="App" style={{ textAlign: "center" }}>

      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/contact-us"
            element={
              <>
                <AppHeader
                  isSearch={true}
                  setActiveSounds={setActiveSounds}
                  selected={0}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <ContactUs />
                  </Suspense>
                  <Foooter />

                </AppHeader>
              </>
            }
          />

          <Route
            path="/disclaimer"
            element={
              <>
                <AppHeader
                  isSearch={true}
                  setActiveSounds={setActiveSounds}
                  selected={0}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <Disclaimer />
                  </Suspense>
                  <Foooter />

                </AppHeader>
              </>
            }
          />

          <Route
            path="/about-us"
            element={
              <>
                <AppHeader
                  isSearch={true}
                  setActiveSounds={setActiveSounds}
                  selected={0}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <AboutUs />
                  </Suspense>
                  <Foooter />

                </AppHeader>
              </>
            }
          />

          <Route
            path="/PrivacyPolicy"
            element={
              <>
                <AppHeader
                  isSearch={true}
                  setActiveSounds={setActiveSounds}
                  selected={0}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <PrivacyPolicy />
                  </Suspense>
                  <Foooter />

                </AppHeader>

              </>
            }
          />
          <Route
            path="/TermsAndConditions"
            element={
              <>
                <AppHeader
                  isSearch={true}
                  setActiveSounds={setActiveSounds}
                  selected={0}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <TermsAndConditions />
                  </Suspense>
                  <Foooter />

                </AppHeader>
              </>
            }
          />
          {["/", "/:name", "/:name/:id"].map((path, index) => (
            <Route
              path={path}
              key={index}
              element={
                <>

                  <AppHeader
                    isSearch={true}
                    setActiveSounds={setActiveSounds}
                    selected={-1}
                  >
                    <div style={{width:'100%' , display:'flex' , justifyContent:'center' , padding:'10px'}} >
{/* <TopAd/> */}

                    </div>
                    <Suspense fallback={<div>Loading...</div>}>
                      {/* <AdBlockDetector /> */}
                      <MainComponent
                        activeSounds={activeSounds}
                        setActiveSounds={setActiveSounds}
                      />


                    </Suspense>
                    <Foooter />

                  </AppHeader>
                </>





              }
            />
          ))}

          <Route
            path="/:index/soundboard"
            element={
              <>
                <AppHeader
                  isSearch={true}
                  setActiveSounds={setActiveSounds}
                  selected={0}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <MainComponent
                      activeSounds={activeSounds}
                      setActiveSounds={setActiveSounds}
                    />
                  </Suspense>
                  <Foooter />

                </AppHeader>
              </>
            }
          />

          <Route
            path="/search/:searchText"
            element={
              <>
                <AppHeader
                  isSearch={true}
                  setActiveSounds={setActiveSounds}
                  selected={-1}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <MainComponent
                      activeSounds={activeSounds}
                      setActiveSounds={setActiveSounds}
                    />
                  </Suspense>
                  <Foooter />

                </AppHeader>
              </>
            }
          />

          <Route
            path="/soundboard/:soundName"
            element={
              <>
                <AppHeader
                  isSearch={false}
                  setActiveSounds={setActiveSounds}
                  selected={-1}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <SoundInfoPage />
                  </Suspense>
                  <Foooter />

                </AppHeader>
              </>
            }
          />



          {["*", "P404"].map((path, index) => (
            <Route
              path={path}
              key={index}
              element={
                <>
                  <AppHeader
                    isSearch={false}
                    setActiveSounds={setActiveSounds}
                    selected={-1}
                  >
                    <Suspense fallback={<div>Loading...</div>}>

                      <P404 />
                    </Suspense>
                    <Foooter />

                  </AppHeader>
                </>
              }
            />
          ))}

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
